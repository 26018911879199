import React, { useCallback, useEffect, useState } from 'react';
import { Box, Spinner, Text, VStack, HStack } from '@chakra-ui/react';
import { colors } from '../../constants/GlobalStyles';
import { appStore, matchStore } from '../../zustand/globalStore';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../../config/firebase';
import { consumeApi } from '../../utils/tools';
import BodyText from '../../components/BodyText';
import { matchStatusSpanish } from '../../constants/GlobalTypesAndEnumsAndConstants';
import i18n from "../../i18n/i18n";
import ItemTournamentMatchComponent from '../../components/ItemTournamentMatchComponent';
import MatchItem from '../../components/MatchItem';

export default function TournamentViewTabMatchesComponent() {

    // Zustand states
    const { currentTournament, setCurrentTournament } = appStore((state) => state);
    const { setCurrentMatch } = matchStore((state) => state);

    // Local states
    const [matches, setMatches] = useState([]);
    const [isMatchesLoading, setIsMatchesLoading] = useState(true);
    const [phases, setPhases] = useState([]);

    // const navigation = useNavigation();

    useEffect(() => {
        if (currentTournament?.is_external) {
            getTopMatches();
        } else {
            getMatchesForTournamentInternal();
        }
    }, [currentTournament]);

    const getMatchesForTournamentInternal = async () => {
        const getMatchByTournament: any = await httpsCallable(functions, 'getMatchByTournament')({
            tournamentId: currentTournament?.id
        });
        console.log("getMatchByTournament?.data?")
        console.log(getMatchByTournament?.data)
        setPhases(getMatchByTournament?.data?.data);
        setIsMatchesLoading(false);
    };

    const getTopMatches = async () => {
        console.log("123132")
        console.log(currentTournament)
        if (!currentTournament.matches) {
            let season = null;
            if (currentTournament?.seasons.length > 0) {
                season = currentTournament?.seasons[0].year;
            }
            const data = await consumeApi(`tournaments/get_matches_by_tournament`, "POST", {
                tournament_id: currentTournament.id,
                season: season
            });
            console.log("data")
            console.log(data)
            if (data.status === "success") {
                const matches = data.matches.slice(0, 20);
                setMatches(matches);
                setCurrentTournament({ ...currentTournament, matches: matches });
            }
            setIsMatchesLoading(false);
        } else {
            setMatches(currentTournament.matches);
            setIsMatchesLoading(false);
        }
    };

    useEffect(() => {
        // trackEvent("TournamentViewTabMatchesComponent", {});
    }, []);

    const RenderGroup = ({ phase, group }: any) => (
        <Box>
            <Box pl="5">
                <BodyText
                    fontWeight="bold"
                    size="small"
                    color={colors.text.black}
                    py="2"
                >
                    {phase.name} - {group.name}
                </BodyText>
            </Box>
            <VStack>
                {group.matches.map((match, index) => (
                    <Box key={index}>
                        <HStack
                            flexDirection="row-reverse"
                            pl="6"
                            position="relative"
                            mb="-3"
                            zIndex="10"
                            w="full"
                            bgColor="rgba(52, 52, 52, alpha)"
                        >
                            <Box
                                bgColor={match.status !== "finished" ? colors.backgrounds.green : colors.backgrounds.lightRed}
                                borderRadius="md"
                                px="3"
                            // align="center"
                            >
                                <BodyText fontWeight="bold" size="extraSmall" color={colors.text.white}>
                                    {match.status ? matchStatusSpanish[match.status] : ""}
                                </BodyText>
                            </Box>
                        </HStack>
                        <MatchItem
                            match={match}
                            onClick={() => {
                                console.log("12312")
                                // setCurrentMatch(null);
                                // navigate("MatchScreen", { matchId: match.id });
                            }}
                        />
                    </Box>
                ))}
            </VStack>
        </Box>
    );

    const renderItemPhases = ({ item }) => (
        <Box>
            {item.have_groups ? (
                <>
                    {item?.groups?.map((group, index) => (
                        <RenderGroup phase={item} group={group} key={index} />
                    ))}
                </>
            ) : (
                <Box>
                    <Box py="2" px="5">
                        <BodyText
                            fontWeight="bold"
                            size="small"
                            color={colors.text.black}
                            ml="1"
                        >
                            {item.name}
                        </BodyText>
                    </Box>
                    {item.matches && item.matches.map((match, index) => (
                        <Box key={index}>
                            <HStack
                                flexDirection="row-reverse"
                                pl="6"
                                position="relative"
                                mb="-3"
                                zIndex="10"
                                w="full"
                                bgColor="rgba(52, 52, 52, alpha)"
                            >
                                <Box
                                    bgColor={match.status !== "finished" ? colors.backgrounds.green : colors.backgrounds.lightRed}
                                    borderRadius="md"
                                    px="3"
                                // align="center"
                                >
                                    <BodyText fontWeight="bold" size="extraSmall" color={colors.text.white}>
                                        {match.status ? matchStatusSpanish[match.status] : ""}
                                    </BodyText>
                                </Box>
                            </HStack>
                            <MatchItem
                                match={match}
                                onClick={() => {
                                    setCurrentMatch(null);
                                    // navigation.navigate("MatchScreen", { matchId: match.id });
                                }}
                            />
                        </Box>
                    ))}
                </Box>
            )}
        </Box>
    );

    const PhasesList = useCallback(() => (
        <Box >
            <VStack>
                {phases?.map((item, index) => (
                    <React.Fragment key={index}>
                        {renderItemPhases({ item })}
                    </React.Fragment>
                ))}
            </VStack>
        </Box>
    ), [phases]);

    return (
        <Box mt="4" gap="4" display="flex" flexDirection="column">
            {isMatchesLoading ? (
                <Box display="flex" justifyContent="center" alignItems="center" gap="2" flexShrink="0" mt="4">
                    <Spinner size='lg' />
                </Box>
            ) : (
                <BodyText
                    color={colors.text.black}
                    textAlign="left"
                    size="extraSmall"
                    fontWeight="semiBold"
                >
                    {i18n.t('matches')}
                </BodyText>
            )}
            {currentTournament?.is_external ? (
                <>
                    {matches.map((match, index) => (
                        <ItemTournamentMatchComponent key={index} match={match} />
                    ))}
                </>
            ) : (
                <PhasesList />
            )}
        </Box>
    );
}
