import React from 'react';
import './App.css';
import { ChakraProvider } from '@chakra-ui/react';
import { BrowserRouter as Router } from "react-router-dom";
import AppRouter from './AppRouter';
import { init } from "@amplitude/analytics-browser";
import { theme } from './constants/GlobalStyles'
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n/i18n';
import '@fontsource-variable/source-sans-3';

function App() {
  React.useEffect(() => {
    // Amplitude Tracking
    init("2180aafe98477376799e297ffe3f9f69");
  }, []);
  return (
    <I18nextProvider i18n={i18n}>
      <ChakraProvider theme={theme}>
        <Router>
          <AppRouter />
        </Router>
      </ChakraProvider>
    </I18nextProvider>
  );
}

export default App;
