import React, { useEffect, useState } from "react";
import { Box, Flex, Text, Spinner } from "@chakra-ui/react";
import { appStore } from "../../zustand/globalStore";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../config/firebase";
import i18n from "../../i18n/i18n";
import { consumeApi } from "../../utils/tools";

interface Team {
    team_name: string;
    match_played: number;
    count_wined: number;
    count_tie: number;
    count_lost: number;
    goals_for: number;
    goals_against: number;
    diference_goals: number;
    points: number;
}

interface StatisticsTeamsGroupProps {
    phase?: any;
    tournament?: any;
}

export default function StatisticsTeamsGroup({ phase, tournament }: StatisticsTeamsGroupProps) {
    
    const { currentTournament } = appStore((state) => state);
    const [teams, setTeams] = useState<any[any]>([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // trackEvent("StatisticsTeamsGroup", {});
        init();
    }, []);

    const init = async () => {
        if (currentTournament?.is_external) {
            try {
                const season = currentTournament?.seasons.length > 0 ? currentTournament.seasons[0].year : null;
                const data = await consumeApi(`tournaments/get_stadistics_for_tournament`, "POST", {
                    tournament_id: currentTournament.id,
                    season
                });
                setTeams(data.data);
                setLoading(false);
            } catch (error) {
                console.log(error);
            }
            return;
        } else {
            try {
                const funtionB = httpsCallable(functions, 'getEstadisticsByPhase');
                const result: any = await funtionB({
                    tournamentId: currentTournament?.id,
                    phaseId: phase?.id
                });
                setTeams(result.data);
                setLoading(false);
            } catch (error) {
                console.log(error);
            }
        }
    };

    const TabTable = () => (
        <Flex
            direction="row"
            justify="space-around"
            py={3}
            px={2}
            align="center"
            wrap="wrap"
        >
            <Box width="6%" textAlign="center">
                <Text fontWeight="semibold" fontSize="sm" color="black">
                    #
                </Text>
            </Box>
            <Box width="38%" textAlign="center">
                <Text fontWeight="semibold" fontSize="sm" color="black">
                    {i18n.t('teams')}
                </Text>
            </Box>
            <Box width="6%" textAlign="center">
                <Text fontWeight="semibold" fontSize="sm" color="black">
                    PJ
                </Text>
            </Box>
            <Box width="6%" textAlign="center">
                <Text fontWeight="semibold" fontSize="sm" color="black">
                    PG
                </Text>
            </Box>
            <Box width="6%" textAlign="center">
                <Text fontWeight="semibold" fontSize="sm" color="black">
                    PE
                </Text>
            </Box>
            <Box width="6%" textAlign="center">
                <Text fontWeight="semibold" fontSize="sm" color="black">
                    PP
                </Text>
            </Box>
            <Box width="6%" textAlign="center">
                <Text fontWeight="semibold" fontSize="sm" color="black">
                    GF
                </Text>
            </Box>
            <Box width="6%" textAlign="center">
                <Text fontWeight="semibold" fontSize="sm" color="black">
                    GC
                </Text>
            </Box>
            <Box width="6%" textAlign="center">
                <Text fontWeight="semibold" fontSize="sm" color="black">
                    DG
                </Text>
            </Box>
            <Box width="10%" textAlign="center">
                <Text fontWeight="semibold" fontSize="sm" color="black">
                    PTS
                </Text>
            </Box>
        </Flex>
    );

    const DataItem = ({ team, filled, index }: { team: Team; filled: boolean; index: number }) => (
        <Flex
            direction="row"
            justify="space-around"
            py={3}
            px={2}
            bg={filled ? "gray.100" : "white"}
            align="center"
            wrap="wrap"
        >
            <Box width="6%" textAlign="center">
                <Box
                    bg="green.500"
                    width="20px"
                    height="20px"
                    borderRadius="full"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    <Text fontWeight="semibold" fontSize="xs" color="white">
                        {index}
                    </Text>
                </Box>
            </Box>
            <Box width="38%" textAlign="center">
                <Text fontWeight="semibold" fontSize="xs" color="black" isTruncated>
                    {team.team_name}
                </Text>
            </Box>
            <Box width="6%" textAlign="center">
                <Text fontWeight="regular" fontSize="xs" color="black">
                    {team.match_played}
                </Text>
            </Box>
            <Box width="6%" textAlign="center">
                <Text fontWeight="regular" fontSize="xs" color="black">
                    {team.count_wined}
                </Text>
            </Box>
            <Box width="6%" textAlign="center">
                <Text fontWeight="regular" fontSize="xs" color="black">
                    {team.count_tie}
                </Text>
            </Box>
            <Box width="6%" textAlign="center">
                <Text fontWeight="regular" fontSize="xs" color="black">
                    {team.count_lost}
                </Text>
            </Box>
            <Box width="6%" textAlign="center">
                <Text fontWeight="regular" fontSize="xs" color="black">
                    {team.goals_for}
                </Text>
            </Box>
            <Box width="6%" textAlign="center">
                <Text fontWeight="regular" fontSize="xs" color="black">
                    {team.goals_against}
                </Text>
            </Box>
            <Box width="6%" textAlign="center">
                <Text fontWeight="regular" fontSize="xs" color="black">
                    {team.diference_goals}
                </Text>
            </Box>
            <Box width="10%" textAlign="center">
                <Text fontWeight="bold" fontSize="xs" color="black">
                    {team.points}
                </Text>
            </Box>
        </Flex>
    );

    if (loading) {
        return (
            <Flex
                direction="row"
                justify="center"
                align="center"
                mt={5}
            >
                <Spinner size="lg" />
            </Flex>
        );
    }

    return (
        <Box>
            {teams?.have_groups ? (
                <>
                    {teams?.data?.map((group: any, index: number) => (
                        <Box key={index} mt={4}>
                            <Text fontWeight="semibold" fontSize="sm" color="black" textAlign="center">
                                {group.name}
                            </Text>
                            <Box
                                bg="gray.50"
                                borderWidth={1}
                                borderRadius="md"
                                borderColor="gray.200"
                                p={4}
                                mt={2}
                            >
                                <TabTable />
                                {group.teams.map((team: Team, index: number) => (
                                    <DataItem key={index} team={team} filled={index % 2 === 0} index={index + 1} />
                                ))}
                            </Box>
                        </Box>
                    ))}
                </>
            ) : (
                <Box
                    bg="gray.50"
                    borderWidth={1}
                    borderRadius="md"
                    borderColor="gray.200"
                    p={4}
                >
                    <TabTable />
                    {teams?.data?.map((team: Team, index: number) => (
                        <DataItem key={index} team={team} filled={index % 2 === 0} index={index + 1} />
                    ))}
                </Box>
            )}
        </Box>
    );
}
