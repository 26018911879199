import i18n from "../i18n/i18n";


export const base_url = "https://wuamball-core-57e4e865a683.herokuapp.com";
// export const base_url = "http://192.168.0.3:5002";
export interface AuthorProps {
  post: PostData;
}

export interface AuthorComments {
  comment: CommentsData;
}

export interface PostApiResponse {
  data: PostData[];
  status: string;
}

export interface PostData {
  comments: CommentsData[],
  countLikes: number;
  created_at: string;
  created_days: number;
  created_hours: number;
  created_minuts: number;
  date_format: string;
  firstname: string;
  id: number;
  incidence: string;
  lastname: string;
  like_user: boolean;
  local_media: LocalMediaData;
  media_url: string;
  num_comments: number;
  num_like: number;
  post_id: number;
  post_text: string;
  post_type: string;
  rate: number;
  upload_media_status: boolean;
  user_id: number;
  user_profile_image: string;
}

export interface CommentsData {
  created_days: number;
  created_hours: number;
  created_minutes: number;
  date_format: string;
  firstname: string;
  id: string;
  image_url: string;
  lastname: string;
  text: string;
  user_id: string;
}

export interface LocalMediaData {
  assetId: string | null;
  base64: string | null;
  duration: number | null;
  exif: any | null;
  height: number;
  rotation: number | null;
  type: string;
  uri: string;
  width: number;
}

export interface TournamentsData {
  status: string;
  tournaments: {
    id: string;
    media_url: string;
    name: string;
  }[];
}

export interface Match {
  goals: {
    away: number;
    home: number;
  };
  id: number;
  league: {
    country: string;
    flag: string;
    id: number;
    logo: string;
    name: string;
    round: string;
    season: number;
  };
  status: {
    elapsed: number;
    long: string;
    short: string;
  };
  teams: {
    away: {
      id: number;
      logo: string;
      name: string;
      winner: boolean;
    };
    home: {
      id: number;
      logo: string;
      name: string;
      winner: boolean;
    };
  };
}

export interface MatchesApiResponse {
  matches: Match[];
  status: string;
}

export const playStoreUrl = "https://play.google.com/store/apps/details?id=com.wuamball.app";
export const appStoreUrl = "https://apps.apple.com/us/app/wuamball/id1665996774";
export enum BackgroundColorsProfile {
  color1 = "#CB62F5",
  color2 = "#54343f",
  color3 = "#7E68E8",
  color4 = "#e77a77",
  color5 = "#6298F5",
  color6 = "#741952",
  color7 = "#94bb68",
  color8 = "#94bb68",
  color9 = "#94bb68",
  color0 = "#94bb68"
}

export type IconProps = {
  color?: any;
  width?: number;
  height?: number;
  style?: any;
};

export enum IncidenceTypes {
  goal = "goal",
  own_goal = "own_goal",
  change_player = "change_player",
  yellow_card = "yellow_card",
  red_card = "red_card",
  team_not_present = "team_not_present"
}

export enum RefereeTypes {
  central = "central",
  first_attendee = "first_attendee",
  second_attendee = "second_attendee",
  quarter_referee = "quarter_referee",
  first_attendee_additional = "first_attendee_additional",
  second_attendee_additional = "second_attendee_additional"
}

export enum TypeNotifications {
  teamParticipation = "team_participation",
  teamInvitation = "team_invitation",
  playerInvitation = "playes_invitation",
  managerInvitation = "manager_invitation",
  technicalStaff = "technical_staff",
  like = "like",
  comment = "comment",
  teamAcceptParticipation = "team_accept_participation",
  playerAcceptInvitation = "playes_accept_invitation",
  managerAcceptInvitation = "manager_accept_invitation",
  manageDeniedtInvitation = "manager_denied_invitation",
  technicalAcceptStaff = "technical_accept_staff",
  technicalDeniedtStaff = "technical_denied_staff",
  teamDeniedParticipation = "team_denied_participation",
  playerDeniedptInvitation = "playes_denied_invitation",
  follow = "following",
  message = "message",
  refereeAssignment = "referee_assignment",

}

export enum matchStatusSpanish {
  // finished = i18n.t('finished'),
  // playing = i18n.t('playing'),
  // pending = i18n.t('pending'),
  // cancel = i18n.t('cancelMatch'),
  // FT = i18n.t('finished'),
  // AET = i18n.t('finished'),
  // PEN = i18n.t('finished'),
  // "1H" = i18n.t('firstTime'),
  // HT = i18n.t('secondTime'),
  // "2H" = i18n.t('halfTime'),
  // TBD = i18n.t('TBD'),
  // NS = i18n.t('NS'),
  // "1H" = i18n.t('1H'),
  // ET = i18n.t('ET'),
  // BT = i18n.t('BT'),
  // P = i18n.t('P'),
  // SUSP = i18n.t('SUSP'),
  // INT = i18n.t('INT'),
  // PST = i18n.t('PST'),
  // CANC = i18n.t('CANC'),
  // ABD = i18n.t('ABD'),
  // AWD = i18n.t('AWD'),
  // WO = i18n.t('WO'),
  // LIVE = i18n.t('LIVE'),
}
