import React from 'react';
import { useMediaQuery, Box, Text, Flex, Image, Avatar } from '@chakra-ui/react';
import { colors } from '../../constants/GlobalStyles'
import { AuthorComments } from '../../constants/GlobalTypesAndEnumsAndConstants';
import i18n from "../../i18n/i18n";

function PostAuthor({ comment }: AuthorComments) {
    const [isMobile] = useMediaQuery('(max-width: 992px)');

    return (
        <>
            <Box marginBottom={6} marginTop={2} paddingLeft={"15px"}>
                <Flex gap={2} p={isMobile ? "2" : "0"} >
                    <Box>
                        { comment.image_url 
                        ?
                        <Image
                            boxSize="40px"
                            borderRadius="100%"
                            src={comment.image_url}
                        />
                        :
                        <Avatar 
                            boxSize="40px"
                            borderRadius="100%"
                            name={ comment.firstname + comment.lastname }
                        />
                        }
                    </Box>
                    <Box>
                        <Box>
                            <Text fontSize="13px"
                                fontWeight="600"
                                lineHeight="18.51px"
                                color={colors.figmaColors.black}
                            >
                                {comment.firstname}{comment.lastname}
                            </Text>
                        </Box>
                        <Box>
                            <Flex gap={2} align="center" justify="center">
                                <Text
                                    fontSize="11px"
                                    fontWeight="400"
                                    lineHeight="18.51px"
                                    color={colors.figmaColors.grey3}
                                >
                                    {comment.created_minutes < 60
                                        ? `${i18n.t('commented on')} ${comment.created_minutes} ${i18n.t('minutes')}`
                                        : comment.created_hours < 48
                                            ? `${i18n.t('commented on')} ${comment.created_hours} ${i18n.t('hours')}`
                                            : comment.created_days < 30
                                                ? `${i18n.t('commented on')} ${comment.created_days} ${i18n.t('days')}`
                                                : `${i18n.t('commented on')} ${comment.date_format}`}

                                </Text>
                            </Flex>
                        </Box>
                    </Box>
                </Flex>
                <Flex justify="start" align="center" paddingLeft="50px">
                    <Text
                        fontSize="13px"
                        fontWeight="400"
                        lineHeight="18.51px"
                        color={colors.figmaColors.grey3}
                    >{comment.text}</Text>
                </Flex>
            </Box>
        </>
    )
};

export default PostAuthor;