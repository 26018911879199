import React, { useEffect, useState } from 'react';
import {
    Box,
    HStack,
    Image,
    Text,
    Spinner,
    Button,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom'; // Usando react-router-dom para la navegación
import i18n from "../../i18n/i18n";
import { appStore } from '../../zustand/globalStore';
import { consumeApi } from '../../utils/tools';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../../config/firebase';

interface Player {
    player: {
        user_id: string;
        photo: string;
        name: string;
        goals: number;
        team: {
            media_url: string;
            name: string;
        };
    };
}

export default function TournamentViewTabPlayersComponent() {
    const navigate = useNavigate(); // Uso de useNavigate en lugar de useNavigation
    const [users, setUsers] = useState<Player[]>([]);
    const { currentTournament, setCurrentTournament } = appStore((state) => state);
    const [topPlayers, setTopPlayers] = useState<Player[]>([]);
    const [isTopPlayersLoading, setIsTopPlayersLoading] = useState(true);

    useEffect(() => {
        getTopPlayers();
    }, [currentTournament]);

    const getTopPlayers = async () => {
        if (!currentTournament?.topPlayers) {
            let season = null;
            if (currentTournament?.seasons?.length > 0) {
                season = currentTournament.seasons[0].year;
            }
            const data = await consumeApi('tournaments/get_players_topscorers', 'POST', {
                tournament_id: currentTournament?.id,
                season,
            });
            if (data.status === 'success') {
                const players = data.top_scorers as Player[];
                setTopPlayers(players);
                setCurrentTournament({ ...currentTournament, topPlayers: players });
            }
            setIsTopPlayersLoading(false);
        } else {
            setTopPlayers(currentTournament.topPlayers);
            setIsTopPlayersLoading(false);
        }
    };

    const init = async () => {
        const funtionB = httpsCallable(functions, 'getRankingPlayersByTournamentGoals');
        funtionB({
            tournamentId: currentTournament?.id,
        })
            .then((result: any) => {
                setUsers(result.data);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    useEffect(() => {
        init();
    }, []);

    return (
        <Box mt={4} w="100%" display="flex" flexDirection="column" gap={4}>
            {isTopPlayersLoading && currentTournament?.is_external && (
                <Box w="100%" textAlign="center">
                    <Spinner size="lg" />
                </Box>
            )}
            {(topPlayers?.length > 0 || users?.length > 0) && (
                <Text fontWeight="semibold" color="black" fontSize="sm" textAlign="center" w="100%">
                    {i18n.t('top_scorers_of_tournament')}
                </Text>
            )}
            {currentTournament?.is_external ? (
                <>
                    {topPlayers.map((player, index) => (
                        <Box key={index} w="100%" p={4} borderWidth={1} borderRadius="md">
                            <Button
                                onClick={() => {
                                    navigate(`/user/${player.player.user_id}`); // Navegación a la página del usuario
                                }}
                                variant="link"
                                w="100%"
                                textAlign="left"
                            >
                                <HStack spacing={4} w="100%">
                                    <Image
                                        src={player.player.photo}
                                        alt={player.player.name}
                                        borderRadius="full"
                                        boxSize="50px"
                                    />
                                    <Box flex="1">
                                        <Text fontSize="sm" color="black">
                                            {player.player.name}
                                        </Text>
                                        <HStack spacing={2} align="center">
                                            <Image
                                                src={player.player.team.media_url}
                                                alt={player.player.team.name}
                                                borderRadius="full"
                                                boxSize="25px"
                                            />
                                            <Text fontSize="xs" color="gray.500">
                                                {player.player.team.name}
                                            </Text>
                                        </HStack>
                                    </Box>
                                    <Text fontWeight="bold" color="black" fontSize="sm" ml="auto">
                                        {`${player.player.goals} ${i18n.t('goals')}`}
                                    </Text>
                                </HStack>
                            </Button>
                        </Box>
                    ))}
                </>
            ) : (
                <>
                    {/* {users.map((item, index) => (
                        <ItemScorersTournament
                            key={index}
                            dataItem={item}
                            filled={index % 2 === 0}
                            index={index + 1}
                        />
                    ))} */}
                </>
            )}
        </Box>
    );
}
