import React from 'react';
import { AuthorProps, playStoreUrl, appStoreUrl } from '../../constants/GlobalTypesAndEnumsAndConstants';
import { colors } from '../../constants/GlobalStyles'
import { useMediaQuery, Flex, Box, Text, Link } from '@chakra-ui/react';
import i18n from "../../i18n/i18n";
import { isMacOs, isIOS } from 'react-device-detect';
import { CommentsIcon, LikeIcon, ShareIcon } from '../../constants/IconsComponent';


export function ShareCount({ post }: AuthorProps) {

    const [isMobile] = useMediaQuery('(max-width: 992px)');
    const CheckLink = isMacOs || isIOS ? appStoreUrl : playStoreUrl;

    return (
        <>
            <Flex gap={2} align="start" justify="start" marginTop={5} p={isMobile ? "2" : "0"}>
                <Box>
                    <Flex gap={1} align="center" justify="center">
                        <Box>
                            <LikeIcon />
                        </Box>
                        <Text
                            fontSize="13px"
                            fontWeight="600"
                            lineHeight="18.51px"
                            color={colors.figmaColors.black}
                        >

                            {post.countLikes === undefined || post.countLikes === 0
                                ? <> <Link target="_blank" href={CheckLink}> 0 {i18n.t('likes')} </Link></>
                                : post.countLikes === 1
                                    ? <> <Link target="_blank" href={CheckLink}> {post.countLikes} {i18n.t('like')} </Link> </>
                                    : post.countLikes > 1
                                        ? <> <Link target="_blank" href={CheckLink}> {post.countLikes} {i18n.t('likes')} </Link> </>
                                        : null}

                        </Text>
                    </Flex>
                </Box>
                <Box>
                    <Flex gap={1} align="center" justify="center">
                        <Box>
                            <CommentsIcon />
                        </Box>
                        <Text
                            fontSize="13px"
                            fontWeight="600"
                            lineHeight="18.51px"
                            color={colors.figmaColors.black}
                        >

                            {post.num_comments === undefined || post.num_comments === 0
                                ? <> <Link target="_blank" href={CheckLink}> 0 {i18n.t('comments')} </Link></>
                                : post.num_comments === 1
                                    ? <> <Link target="_blank" href={CheckLink}> {post.num_comments} {i18n.t('comment')} </Link> </>
                                    : post.num_comments > 1
                                        ? <> <Link target="_blank" href={CheckLink}> {post.num_comments} {i18n.t('comments')} </Link> </>
                                        : null}

                        </Text>
                    </Flex>
                </Box>

                <Box marginLeft="auto">
                    <Flex gap={1} align="center" justify="center">
                        <Box>
                            <ShareIcon />
                        </Box>
                        <Text
                            fontSize="13px"
                            fontWeight="600"
                            lineHeight="18.51px"
                            color={colors.figmaColors.secColor}
                        >
                            <Link target="_blank" href={CheckLink}> {i18n.t('share')} </Link>
                        </Text>
                    </Flex>
                </Box>
            </Flex>
        </>
    )
};

export default ShareCount;