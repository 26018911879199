import React from "react";
import { Box, Text, Link, useStyleConfig } from "@chakra-ui/react";
import { colors } from "../constants/GlobalStyles";

interface BodyTextProps {
  color?: string;
  style?: React.CSSProperties;
  fontWeight?: "light" | "regular" | "medium" | "semiBold" | "bold";
  fontStyle?: "italic" | "underline" | "light";
  size?:
    | "xxLarge"
    | "extraLarge"
    | "large"
    | "xMedium"
    | "medium"
    | "small"
    | "extraSmall"
    | "overline"
    | "xxSmall";
  textAlign?: "left" | "center" | "right";
  maxNumberOfLines?: number;
  hideShowMore?: boolean;
  [key: string]: any;
}

export default function BodyText({
  color = colors.text.body,
  style,
  fontWeight,
  fontStyle,
  size,
  textAlign,
  maxNumberOfLines,
  hideShowMore = false,
  ...otherProps
}: BodyTextProps) {
  const textStyles = useStyleConfig("Text", {
    fontWeight,
    fontStyle,
    size,
    textAlign,
    color,
  });

  const fontWeights = {
    light: 300,
    regular: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
  };

  const renderViewMore = (onPress: () => void) => (
    <Link onClick={onPress}>Ver más</Link>
  );

  const renderViewLess = (onPress: () => void) => (
    <Link onClick={onPress}>Ver menos</Link>
  );

  return (
    <Box>
      {maxNumberOfLines ? (
        <Text
          sx={{
            ...textStyles,
            fontWeight: fontWeights[fontWeight] || fontWeights.regular,
          }}
          {...otherProps}
        />
      ) : (
        <Text
          sx={{
            ...textStyles,
            fontWeight: fontWeights[fontWeight] || fontWeights.regular,
          }}
          {...otherProps}
        />
      )}
    </Box>
  );
}

// You can add this in a separate file for Chakra's `useStyleConfig` custom theme
export const styles = {
  textStyle: {
    // fontFamily: "sofia-pro-light-az",
  },
  xxLarge: {
    fontSize: "22px",
    flexWrap: "wrap",
  },
  extraLarge: {
    fontSize: "20px",
    lineHeight: "32px",
    flexWrap: "wrap",
  },
  large: {
    fontSize: "18px",
    lineHeight: "28px",
    flexWrap: "wrap",
  },
  xMedium: {
    fontSize: "17px",
    lineHeight: "28px",
    flexWrap: "wrap",
  },
  medium: {
    fontSize: "16px",
    lineHeight: "24px",
    flexWrap: "wrap",
  },
  small: {
    fontSize: "14px",
    lineHeight: "20px",
    flexWrap: "wrap",
  },
  extraSmall: {
    fontSize: "13px",
    lineHeight: "16px",
    flexWrap: "wrap",
  },
  xxSmall: {
    fontSize: "11px",
    lineHeight: "16px",
    flexWrap: "wrap",
  },
  overline: {
    textTransform: "uppercase",
    fontSize: "10px",
    lineHeight: "16px",
    fontWeight: "900",
  },
  italic: {
    fontStyle: "italic",
    // fontFamily: "sofia-pro-regular-italic-az",
  },
  underline: {
    textDecorationLine: "underline",
  },
};
