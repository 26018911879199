import { useState } from "react";
import {
  Box,
  Input,
  InputGroup,
  InputRightElement,
  FormControl,
  FormLabel,
  FormErrorMessage,
  IconButton,
} from "@chakra-ui/react";
import {
  InputErrorIcon,
  EyeOpenedIcon,
  EyeClosedIcon,
} from "../constants/IconsComponent";
import BodyText from "./BodyText";
import i18n from "../i18n/i18n";

const CustomTextInput = ({
  placeholder,
  textField,
  isRequired,
  onChangeText,
  isPassword,
  showError,
  marginTop,
}) => {
  const [inputValue, setInputValue] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const handleChange = (e) => {
    const text = e.target.value;
    setInputValue(text);
    if (onChangeText) {
      onChangeText(text);
    }
  };

  return (
    <Box mt={marginTop} width="100%">
      <FormControl
        display="flex"
        flexDirection="column"
        isRequired={isRequired}
        isInvalid={showError}
        gap="4px"
      >
        <FormLabel
          display="flex"
          flexDirection="row"
          fontWeight={600}
          gap={1}
          mb={0}
          ml={4}
          requiredIndicator={null}
          fontSize={14}
        >
          {textField}
          {isRequired && (
            <BodyText fontSize={13} fontWeight="regular" as="span">
              {i18n.t("required")}
            </BodyText>
          )}
        </FormLabel>
        <InputGroup>
          <Input
            type={isPassword && !showPassword ? "password" : "text"}
            placeholder={placeholder}
            value={inputValue}
            onChange={handleChange}
            border="1px"
            borderColor="gray.300"
            borderRadius="20px"
            height="60px"
            fontSize="14px"
            color="black"
            formNoValidate={true}
            aria-required={isRequired ? true : false}
            css={{
              "::-ms-reveal": { display: "none" },
            }}
          />
          {isPassword && (
            <InputRightElement top="10px" right="5px">
              <IconButton
                aria-label={showPassword ? "Hide password" : "Show password"}
                icon={showPassword ? <EyeClosedIcon /> : <EyeOpenedIcon />}
                onClick={() => setShowPassword(!showPassword)}
                display="flex"
                variant="unstyled"
                size="sm"
              />
            </InputRightElement>
          )}
        </InputGroup>
        {showError && (
          <FormErrorMessage color="red.500" mt={0} gap="4px">
            <InputErrorIcon />
            <BodyText>{i18n.t("input_error")}</BodyText>
          </FormErrorMessage>
        )}
      </FormControl>
    </Box>
  );
};

export default CustomTextInput;
