import { useState, useEffect } from 'react';
import { Box, Flex, Text, Button, Grid, Spinner, Center } from '@chakra-ui/react';
import { appStore } from '../../zustand/globalStore';
import { consumeApi } from '../../utils/tools';
import i18n from '../../i18n/i18n';

export default function TournamentViewTabDetailsComponent() {
    // Zustand states
    const { currentTournament, setCurrentTournament } = appStore((state) => state);
    // Local states
    const [topPlayers, setTopPlayers] = useState([]);
    const [isTopPlayersLoading, setIsTopPlayersLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [posts, setPosts] = useState([]);

    useEffect(() => {
        try {
            loadPosts();
        } catch (e) {
            console.error("Error loading posts:", e);
        }
    }, []);

    useEffect(() => {
        // trackEvent("TournamentViewTabDetailsComponent", {});
    }, []);

    const loadPosts = async () => {
        if (!hasMore || loading) return;
        setLoading(true);
        try {
            const response = await consumeApi(`posts/get_posts_by_user`, "POST", {
                user_id: currentTournament.id,
                limit: 20,
                page: page
            });
            if (response.data.length > 0) {
                setPosts((prevPosts) => [...prevPosts, ...response.data]);
                setPage((prevPage) => prevPage + 1);
            }
            setHasMore(response.data.length > 0);
        } catch (error) {
            console.error("Failed to load posts:", error);
        }
        setLoading(false);
    };

    const renderPost = (item: any) => {
        return (
            item.media_url && (
                <Box
                    as="button"
                    onClick={() => {
                        // navigation.navigate("PostScreenInside", { post: item })
                    }}
                    w="30%"
                    p={2}
                >
                    {/* <VideoItemGrid post={item} /> */}
                </Box>
            )
        );
    };

    const notResult = () => {
        return (
            <Center h="30vh">
                <Text fontWeight="semibold" fontSize="xl" color="black">
                    {i18n.t('dontPostYet')}
                </Text>
                <Text fontWeight="regular" fontSize="sm" color="gray.400">
                    {i18n.t('coming_soon')}
                </Text>
            </Center>
        );
    };

    return (
        <Box mt={4} w="100%">
            {posts.length > 0 ? (
                <>
                    <Flex justify="space-between" align="flex-start" w="100%">
                        <Text fontWeight="semibold" fontSize="xs">
                            {i18n.t('relive_the_best_moments')}
                        </Text>
                        {/* <Button variant="link" color="blue.500" onClick={() => console.log("See all action")}>
                            <Text fontWeight="semibold" fontSize="xs">
                                {i18n.t('see_all')}
                            </Text>
                        </Button> */}
                    </Flex>
                    <Grid templateColumns="repeat(3, 1fr)" gap={2}>
                        {posts.map((post, index) => renderPost(post))}
                    </Grid>
                    {loading && (
                        <Center>
                            <Spinner size="lg" color="blue.500" />
                        </Center>
                    )}
                </>
            ) : (
                notResult()
            )}
        </Box>
    );
}

const styles = {
    tab_active: {
        display: "flex",
        padding: 10,
        justifyContent: "center",
        alignItems: "center",
        gap: 10,
        borderBottomWidth: 2,
        borderBottomColor: "black",
    },
    tab_inactive: {
        display: "flex",
        padding: 10,
        justifyContent: "center",
        alignItems: "center",
        gap: 10,
        borderBottomWidth: 2,
        borderBottomColor: "gray.300",
    },
};
