import { ReactComponent as WuamballIconSVG } from "../components/imgs/icons/wuamball-icon.svg";
import { ReactComponent as PublicIconSVG } from "../components/imgs/icons/public-icon.svg";
import { ReactComponent as EllipseIconSVG } from "../components/imgs/icons/ellipse.svg";
import { ReactComponent as LikeIconSVG } from "../components/imgs/icons/like-icon.svg";
import { ReactComponent as CommentsIconSVG } from "../components/imgs/icons/comments-icon.svg";
import { ReactComponent as ShareIconSVG } from "../components/imgs/icons/share-icon.svg";
import { ReactComponent as AppleStoreIconSVG } from "../components/imgs/icons/apple-store.svg";
import { ReactComponent as GooglePlayIconSVG } from "../components/imgs/icons/google-play-icon.svg";
import { ReactComponent as SendIconSVG } from "../components/imgs/icons/send-icon.svg";
import { ReactComponent as ArrowIconSVG } from "../components/imgs/icons/arrow-right-icon.svg";
import { ReactComponent as NotificationIconSVG } from "../assets/icons/new-icons/notification-ico.svg";
import { ReactComponent as MetaIconSVG } from "../assets/images/login-screen/meta-btn.svg";
import { ReactComponent as GoogleIconSVG } from "../assets/images/login-screen/google-btn.svg";
import { ReactComponent as AppleIconSVG } from "../assets/images/login-screen/apple-btn.svg";
import { ReactComponent as InputErrorSVG } from "../assets/icons/new-icons/input-error.svg";
import { ReactComponent as EyeOpenedSVG } from "../assets/icons/new-icons/eye-opened.svg";
import { ReactComponent as EyeClosedSVG } from "../assets/icons/new-icons/eye-closed.svg";

export const WuamballIcon = (props: any) => {
  return <WuamballIconSVG {...props} />;
};
export const PublicIcon = (props: any) => {
  return <PublicIconSVG {...props} />;
};
export const EllipseIcon = (props: any) => {
  return <EllipseIconSVG {...props} />;
};
export const LikeIcon = (props: any) => {
  return <LikeIconSVG {...props} />;
};
export const CommentsIcon = (props: any) => {
  return <CommentsIconSVG {...props} />;
};
export const ShareIcon = (props: any) => {
  return <ShareIconSVG {...props} />;
};
export const AppleStoreIcon = (props: any) => {
  return <AppleStoreIconSVG {...props} />;
};
export const GooglePlayIcon = (props: any) => {
  return <GooglePlayIconSVG {...props} />;
};
export const SendIcon = (props: any) => {
  return <SendIconSVG {...props} />;
};
export const ArrowIcon = (props: any) => {
  return <ArrowIconSVG {...props} />;
};
export const MetaIcon = (props: any) => {
  return <MetaIconSVG {...props} />;
};
export const GoogleIcon = (props: any) => {
  return <GoogleIconSVG {...props} />;
};
export const AppleIcon = (props: any) => {
  return <AppleIconSVG {...props} />;
};
export const NotificationIcon = (props: any) => {
  return <NotificationIconSVG {...props} />;
};

export const InputErrorIcon = (props: any) => {
  return <InputErrorSVG {...props} />;
};

export const EyeOpenedIcon = (props: any) => {
  return <EyeOpenedSVG {...props} />;
};

export const EyeClosedIcon = (props: any) => {
  return <EyeClosedSVG {...props} />;
};
