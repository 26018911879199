import React from 'react';
import { useMediaQuery, Flex, Box, Text, Link } from '@chakra-ui/react';
import { colors } from '../../constants/GlobalStyles'
import { isMacOs, isIOS } from 'react-device-detect';
import i18n from "../../i18n/i18n";
import { playStoreUrl, appStoreUrl } from '../../constants/GlobalTypesAndEnumsAndConstants';
import { SendIcon } from '../../constants/IconsComponent';

export function Comments() {
    const [isMobile] = useMediaQuery('(max-width: 992px)');
    const CheckLink = isMacOs || isIOS ? appStoreUrl : playStoreUrl;
    return (
        <>
            <Box p={isMobile ? "2" : "0"} >
                <Link target="_blank" href={CheckLink}>
                    <Flex
                        justify="space-between"
                        align="center"
                        p={2}
                        paddingLeft="20px"
                        bg={colors.figmaColors.grey0}
                        borderRadius="20px"
                        height="40px"
                    >
                        <Text
                            fontSize="13px"
                            fontWeight="400"
                            lineHeight="18.51px"
                            color={colors.figmaColors.grey3}
                        >
                            {i18n.t('write a comment')}
                        </Text>
                        <SendIcon cursor="pointer" />
                    </Flex>
                </Link>
            </Box>
        </>
    )
};

export default Comments;