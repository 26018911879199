import React, { useEffect, useState } from 'react';
import {
    Box,
    Image,
    Button,
    Text,
    Flex,
    VStack,
    HStack,
} from '@chakra-ui/react';
// import ImageFromText from '../../../components/ImageFromText';
// import { appStore } from '../../../zustand/globalStore';
// import NotificationIcon from '../../assets/icons/new-icons/notification-ico.svg'

// import { consumeApi, trackEvent } from '../../../assets/tools';
import i18n from "../../i18n/i18n";
import { consumeApi } from '../../utils/tools';
import ImageFromText from '../../components/ImageFromText';
import { NotificationIcon } from '../../constants/IconsComponent';
import { appStore } from '../../zustand/globalStore';

export default function TournamentHeaderComponent() {
    // zustand states
    const { currentTournament } = appStore((state) => state);


    console.log("currentTournament", currentTournament);

    // local state
    const [numberFollowers, setNumberFollowers] = useState(currentTournament?.number_followers);
    // const [numberFollowers, setNumberFollowers] = useState(currentTournament?.number_followers || 0);
    const [isFollowing, setIsFollowing] = useState(currentTournament?.is_following);
    const [isFollowingLoading, setIsFollowingLoading] = useState(false);

    useEffect(() => {
        // trackEvent('TournamentHeaderComponent', {});
    }, []);

    const followOrUnfollowTournament = async () => {
        // setIsFollowingLoading(true);
        // const data = await consumeApi(
        //     `users/${isFollowing ? 'unfollow_user' : 'follow_user'}`,
        //     'POST',
        //     {
        //         user_id: userData?.id,
        //         user_id_to_follow: currentTournament?.id,
        //         user_id_to_unfollow: currentTournament?.id,
        //     }
        // );
        // if (data.status === 'success') {
        //     setNumberFollowers(data.number_followers);
        //     setIsFollowing(!isFollowing);
        // }
        // setIsFollowingLoading(false);
    };

    return (
        <Box>
            <Box
                display="flex"
                width="100%"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
            >
                <Box
                    borderRadius="20px"
                    overflow="hidden"
                    // gap={10}
                    width="100%"
                >
                    <Box
                        background={`linear-gradient(rgba(0, 0, 0, 0.60), rgba(0, 0, 0, 0.60)), url(${require('../../assets/images/defaultBackgroundTournamentProfile.jpg')})`}
                        bgSize="cover"
                        bgPosition="center"
                        bgRepeat="no-repeat"
                        p={6}
                    >
                        <VStack align="center" >
                            <Text color="white" fontSize={'x-large'} fontWeight={700}>
                                {currentTournament?.name}
                            </Text>
                            <Flex
                                justifyContent="space-between"
                                alignItems="center"
                                width="100%"
                            >

                                <HStack spacing={3} maxW="50%">
                                    <Box {...styles.tournamentIcon}>
                                        {currentTournament?.media_url ? (
                                            <Image
                                                src={currentTournament.media_url}
                                                borderRadius="60%"
                                                boxSize="69px"
                                            />
                                        ) : (
                                            <ImageFromText
                                                text={currentTournament?.name ? currentTournament.name.charAt(0) : ''}
                                                sizeIcon={styles.round_icon}
                                                sizeText="large"
                                            />
                                        )}
                                    </Box>
                                    <VStack align="flex-start">
                                        <HStack spacing={2}>
                                            {
                                                currentTournament?.country?.flag && <Image src={currentTournament?.country?.flag} width="16px" height="21px" />
                                            }

                                            <Text color="white" fontSize="xs">
                                                {currentTournament?.place_description}
                                            </Text>
                                        </HStack>
                                    </VStack>
                                </HStack>
                                <VStack align="flex-start" spacing={1}>
                                    {/* <Button
                                        onClick={followOrUnfollowTournament}
                                        size="sm"
                                        variant="outline"
                                        borderColor="white"
                                        colorScheme={isFollowing ? 'blue' : undefined}
                                        isLoading={isFollowingLoading}
                                    >
                                        {isFollowing ? t('unfollow') : t('follow')}
                                        <NotificationIcon />
                                    </Button> */}
                                    <Text color="white" fontSize="xs">
                                        {numberFollowers} {i18n.t('followers')}
                                        {currentTournament?.number_followers || 0} {i18n.t('followers')}
                                    </Text>
                                </VStack>
                            </Flex>
                            {currentTournament?.seasons?.length > 0 && (
                                <HStack spacing={3}>
                                    <Text color="white" fontSize="sm">
                                        {i18n.t('season')}:
                                    </Text>
                                    <Text fontWeight="semibold" color="white" fontSize="sm">
                                        {currentTournament?.seasons[0].year}
                                    </Text>
                                    {/* <ArrowDown /> */}
                                </HStack>
                            )}
                            <Box
                                bg="blue.500"
                                // position="absolute"
                                right={0}
                                bottom={0}
                                borderRadius="20px"
                                py={1}
                                px={3}
                            >
                                <Text color="white" fontSize="xs">
                                    {currentTournament?.hashtag}
                                </Text>
                            </Box>

                        </VStack>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}

const styles = {
    round_icon: {
        borderRadius: '50%',
        width: '50px',
        height: '50px',
    },
    tournamentIcon: {
        display: 'flex',
        width: '70px',
        height: '70px',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '50%',
        backgroundColor: 'white',

    },
};
