import { Button } from "@chakra-ui/react";

const ButtonComponent = ({ children, variant, ...props }) => {
  const variantStyles = {
    variantAccess: {
      display: "flex",
      height: "60px",
      padding: "0px 20px",
      justifyContent: "center",
      alignItems: "center",
      gap: "8px",
      alignSelf: "stretch",
      borderRadius: "20px",
      background: "linear-gradient(91deg, #0D2C8D 0.66%, #5978D9 99.44%)",
      _hover: {
        background: "linear-gradient(91deg, #0A1F6F 0.66%, #4863B7 99.44%)",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
      },
    },
  };

  const variantStyle = variantStyles[variant] || {};

  return (
    <Button {...props} sx={variantStyle}>
      {children}
    </Button>
  );
};

export default ButtonComponent;
