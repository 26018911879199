
import React, { useEffect, useState } from 'react';
import { useMediaQuery, Box, Text, Flex, Image, Link, Spinner, VStack, Center, SkeletonText, Skeleton } from '@chakra-ui/react';
import { colors } from "../../constants/GlobalStyles"
import { consumeApi } from '../../utils/tools';
import { MatchesApiResponse, playStoreUrl, appStoreUrl } from '../../constants/GlobalTypesAndEnumsAndConstants'
import i18n from "../../i18n/i18n";
import { ArrowIcon } from '../../constants/IconsComponent'
import { isMacOs, isIOS } from 'react-device-detect';
import CustomImage from './CustomImage';



const MatchLivesComponent = () => {

    const [isMobile] = useMediaQuery('(max-width: 992px)');
    const [apiResponse, setApiResponse] = useState<MatchesApiResponse>();
    const [loader, setLoader] = useState(true);
    const [scrollLeagues, setScrollLeagues] = useState<Boolean>()
    const CheckLink = isMacOs || isIOS ? appStoreUrl : playStoreUrl;

    let requestBody = {
        "user_id": "anonymous"
    };

    const fetchData = async () => {
        setLoader(true)
        try {
            const data = await consumeApi("matches/live_matches", "GET", requestBody);
            setApiResponse(data);
            setLoader(false)
        } catch (error) {
        }
    };

    useEffect(() => {
        const fetchDataWithTimer = async () => {
            fetchData();
            const interval = setInterval(() => {
                fetchData();
            }, 300000);
            return () => clearInterval(interval);
        };
        fetchDataWithTimer();
    }, []);

    useEffect(() => {
        window.addEventListener('scroll', () => {
            if (window.scrollY >= 100) {
                setScrollLeagues(true);
            } else {
                setScrollLeagues(false);
            }
        });
    }, []);


    return (
        <>
            {!loader ?
                <Box
                    zIndex={isMobile ? "unset" : "unset"}
                    bg={isMobile ? colors.figmaColors.appBgColor : colors.surface.white}
                    padding={isMobile ? "10px" : "25px"}
                    borderRadius={isMobile ? "0" : "20px"}
                    width={!isMobile ? "100%" : "100%"}
                    marginTop={isMobile ? "0" : "25px"}
                    position={isMobile ? "relative" : "relative"}
                >
                    <Flex justify="space-between" align="center" marginBottom={4} width={"100%"}>
                        <Box>
                            <Text fontWeight="600" fontSize="14px" lineHeight="19.94px"> {i18n.t('live matches')} </Text>
                        </Box>
                        <Box marginLeft={"auto"}>
                            
                        { isMobile ?
                            <Flex justify={"center"} align={"center"} gap={2}>
                                <Link target="_blank" href={CheckLink}>
                                    <Text
                                        fontWeight="600"
                                        fontSize="14px"
                                        lineHeight="19.94px"
                                        color={colors.figmaColors.secColor}
                                    >
                                        {i18n.t('see all')}
                                    </Text>
                                </Link>
                                <ArrowIcon />
                            </Flex>
                        : null }

                        </Box>
                        <Flex>

                        </Flex>
                    </Flex>
                    {apiResponse && apiResponse.matches.slice(0, isMobile ? 3 : undefined).map(match => (
                        <Box
                            key={match.id}
                            bg={colors.figmaColors.grey0}
                            paddingX={"20px"}
                            paddingY={"10px"}
                            border={"1px solid" + colors.figmaColors.grey1}
                            borderRadius={"20px"}
                            marginBottom={"10px"}
                            position={"relative"}
                        >
                            <Box
                                position={"absolute"}
                                top={"5px"}
                                right={"5px"}
                                bg={colors.figmaColors.priColor}
                                width={"45px"}
                                height={"22px"}
                                borderRadius={"10px"}
                            >
                                <Flex align={"center"} justify={"center"} height={"100%"}>
                                    <Text
                                        align={"center"}
                                        fontSize={"11px"}
                                        fontWeight={"700"}
                                        lineHeight={"15.66px"}
                                    >
                                        {match.status.elapsed}’
                                    </Text>
                                </Flex>
                            </Box>
                            <Box>
                                <Flex gap={2} align={"center"} justify={"start"} mb={"10px"}>
                                    { match.league.flag 
                                    ? <Image width={"16px"} height={"11px"} src={match.league.flag} />
                                    : <Box width={"16px"} height={"11px"} bg={colors.figmaColors.grey1}/> }
                                    
                                    <Text fontSize={"13px"} fontWeight={"600"}> {match.league.name} </Text>
                                    <Text fontSize={"13px"} fontWeight={"600"} color={colors.figmaColors.grey3}> {match.league.round} </Text>
                                </Flex>
                            </Box>
                            <Flex gap={1} align={"center"} justify={"center"}>
                                <Box
                                    flex={"35%"}
                                    overflow={"hidden"}
                                >
                                    <Flex flexDirection={"column"} align={"center"} justify={"center"}>
                                        <Image boxSize={"25px"} src={match.teams.home.logo + "?version=" + match.teams.home.id } />
                                        <Text
                                            textAlign={"center"}
                                            fontWeight={"600"}
                                            fontSize={"14px"}
                                            lineHeight={"19.94px"}
                                            color={colors.figmaColors.black}
                                        >
                                            {match.teams.home.name}
                                        </Text>
                                    </Flex>
                                </Box>
                                <Box
                                    flex={"12.5%"}
                                    boxSize={"50px"}

                                >
                                    <Flex
                                        align={"center"}
                                        justify={"center"}
                                        boxSize={"50px"}
                                        borderRadius={"20px"}
                                        fontWeight={600}
                                        fontSize={"17px"}
                                        lineHeight={"24.21px"}
                                        bg={colors.surface.white}
                                        width={"50px"}
                                        margin={"auto"}
                                    >
                                        <Text>{match.goals.home}</Text>
                                    </Flex>
                                </Box>
                                <Box flex={"5%"}>
                                    <Text fontWeight={"600"} fontSize={"17px"} textAlign={"center"}> - </Text>
                                </Box>
                                <Box
                                    flex={"12.5%"}
                                    boxSize={"50px"}
                                >
                                    <Flex
                                        align={"center"}
                                        justify={"center"}
                                        boxSize={"50px"}
                                        borderRadius={"20px"}
                                        fontWeight={"600"}
                                        fontSize={"17px"}
                                        lineHeight={"24.21px"}
                                        bg={colors.surface.white}
                                        width={"50px"}
                                        margin={"auto"}
                                    >
                                        <Text>{match.goals.away}</Text>
                                    </Flex>
                                </Box>
                                <Box
                                    flex={"35%"}
                                    overflow={"hidden"}
                                >
                                    <Flex flexDirection={"column"} align={"center"} justify={"center"}>
                                        <Image boxSize={"25px"} src={match.teams.away.logo + "?version=" + match.teams.away.id } />
                                        <Text
                                            textAlign={"center"}
                                            fontWeight={"600"}
                                            fontSize={"14px"}
                                            lineHeight={"19.94px"}
                                            color={colors.figmaColors.grey3}
                                        >
                                            {match.teams.away.name}
                                        </Text>
                                    </Flex>
                                </Box>

                            </Flex>
                            <Text
                                fontSize={"11px"}
                                fontWeight={"400"}
                                lineHeight={"15.66px"}
                                color={colors.figmaColors.secColor}
                                textAlign={"center"}
                                mt={"10px"}
                            >
                                {i18n.t(match.status.long)}
                            </Text>
                        </Box>
                    ))}
                </Box>
                : null}

            {loader ?
                <Box 
                    zIndex={"unset"}
                    bg={isMobile ? colors.figmaColors.appBgColor : colors.surface.white}
                    padding={isMobile ? "10px" : "25px"}
                    borderRadius={isMobile ? "0" : "20px"}
                    width={"100%"}
                    marginTop={isMobile ? "0" : "25px"}
                    position={"relative"}
                    >
                    <Box
                        display="flex"
                        flexDirection="column"
                        gap="10px"
                        justifyContent="start"
                        width="100%"
                        >
                        <Skeleton height='100px' borderRadius={"20px"} />
                        <Skeleton height='100px' borderRadius={"20px"} />
                        <Skeleton height='100px' borderRadius={"20px"} />
                    </Box>
                </Box >

                : null}
        </>
    );

}

export default MatchLivesComponent;
