import React, { useEffect, useState } from 'react';
import { Box, Button } from '@chakra-ui/react';
import i18n from "../../i18n/i18n";
import BodyText from '../../components/BodyText';
import { colors } from '../../constants/GlobalStyles';
import TournamentViewTabDetailsComponent from './TournamentViewTabDetailsComponent';
import TournamentViewTabMatchesComponent from './TournamentViewTabMatchesComponent';
import TournamentViewTabStandingsComponent from './TournamentViewTabStandingsComponent';
import TournamentViewTabPlayersComponent from './TournamentViewTabPlayersComponent';
// import TournamentViewTabDetailsComponent from './TournamentViewTabDetailsComponent';
// import TournamentViewTabMatchesComponent from './TournamentViewTabMatchesComponent';
// import TournamentViewTabStandingsComponent from './TournamentViewTabStandingsComponent';
// import TournamentViewTabPlayersComponent from './TournamentViewTabPlayersComponent';



export default function TournamentViewTabComponent() {

    //local states
    const [selectedTab, setSelectedTab] = useState("matches");

    useEffect(() => {
        // trackEvent("TournamentViewTabComponent", {});
    }, []);

    return (
        <Box sx={styles.container}>
            <Box
                display="flex"
                alignItems="flex-end"
                alignSelf="stretch"
                flexDirection="row"
            >
                <Button
                    variant="ghost"
                    onClick={() => setSelectedTab("matches")}
                    sx={selectedTab === 'matches' ? styles.tab_active : styles.tab_inactive}
                >
                    <BodyText fontStyle="light"
                        color={selectedTab === 'matches' ? colors.text.black : colors.light.tabIconDefault}
                        textAlign="center"
                        size="extraSmall">
                        {i18n.t('myMatches')}
                    </BodyText>
                </Button>
                <Button
                    variant="ghost"
                    onClick={() => setSelectedTab("standings")}
                    sx={selectedTab === 'standings' ? styles.tab_active : styles.tab_inactive}
                >
                    <BodyText fontStyle="light"
                        color={selectedTab === 'standings' ? colors.text.black : colors.light.tabIconDefault}
                        textAlign="center"
                        size="extraSmall">
                        {i18n.t('standings')}
                    </BodyText>
                </Button>
                <Button
                    variant="ghost"
                    onClick={() => setSelectedTab("players")}
                    sx={selectedTab === 'players' ? styles.tab_active : styles.tab_inactive}
                >
                    <BodyText fontStyle="light"
                        color={selectedTab === 'players' ? colors.text.black : colors.light.tabIconDefault}
                        textAlign="center"
                        size="extraSmall">
                        {i18n.t('scorers')}
                    </BodyText>
                </Button>
                <Button
                    variant="ghost"
                    onClick={() => setSelectedTab("details")}
                    sx={selectedTab === 'details' ? styles.tab_active : styles.tab_inactive}
                >
                    <BodyText fontStyle="light"
                        color={selectedTab === 'details' ? colors.text.black : colors.light.tabIconDefault}
                        textAlign="center" size="extraSmall">
                        {i18n.t('details')}
                        {i18n.t('publications')}
                    </BodyText>
                </Button>
            </Box >
            {selectedTab === "details" && <TournamentViewTabDetailsComponent />}
            {selectedTab === "matches" && <TournamentViewTabMatchesComponent />}
            {selectedTab === "standings" && <TournamentViewTabStandingsComponent />}
            {selectedTab === "players" && <TournamentViewTabPlayersComponent />}
        </Box>
    )
}

const styles = {
    container: {
        // You can add styles for the container if needed.
    },
    tab_active: {
        padding: 10,
        justifyContent: "center",
        alignItems: "center",
        gap: 10,
        borderBottomWidth: "2px",
        borderBottomColor: colors.text.black,
        background: 'none',
        _hover: {
            background: 'none',
        },
    },
    tab_inactive: {
        padding: 10,
        justifyContent: "center",
        alignItems: "center",
        gap: 10,
        borderBottomWidth: "2px",
        borderBottomColor: colors.light.tabIconDefault,
        background: 'none',
        _hover: {
            background: 'none',
        },
    }
};
