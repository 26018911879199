import { Box, Heading, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { consumeApi } from "../../utils/tools";
import TournamentHeaderComponent from "./TournamentHeaderComponent";
import TournamentViewTabComponent from "./TournamentViewTabComponent";
import { appStore } from "../../zustand/globalStore";

function TournamentPage() {

    //zustand states
    const { currentTournament, userData, setCurrentTournament, setShowBottomBar, setShowTopBar } = appStore((state) => state)
    // const { setCurrentMatch } = matchStore((state) => state)

    // const [loading, setLoading] = useState(true)
    // const isFocused = useIsFocused()


    const { tournamentId } = useParams();

    const init = async () => {
        try {
            const data = await consumeApi("tournaments/get_tournament_by_id", "POST", {
                tournament_id: tournamentId,
                user_id: ''
            });
            if (data.status === "success") {
                setCurrentTournament(data.tournament)
            }
        } catch (error) {
        }
    }

    useEffect(() => {
        init()
    }, []);

    return (
        <Box marginTop="68px" p="0" >
            <TournamentHeaderComponent />
            <TournamentViewTabComponent />
        </Box>
    );
}

export default TournamentPage;
