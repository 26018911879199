import { Box, Heading, Text } from "@chakra-ui/react";
import i18n from "../i18n/i18n";


function TermsAndConditions() {


  return (
    <Box maxW="800px" mx="auto" marginTop="68px" p="0">
      <Heading as="h1" fontSize="2xl" fontWeight="bold" mb="4">
        <Text>{i18n.t("terms_little")}</Text>
      </Heading>
      <Text fontSize="lg">{i18n.t("terms_little_text")}</Text>
      <Heading as="h2" fontSize="xl" fontWeight="bold" mt="4">
        <Text>{i18n.t("terms_intended_purpose_little")}</Text>
      </Heading>
      <Text fontSize="lg" ml="4">
        {i18n.t("terms_intended_purpose_text")}
      </Text>
      <Heading as="h2" fontSize="xl" fontWeight="bold" mt="4">
        <Text>{i18n.t("terms_limitations_little")}</Text>
      </Heading>
      <Text fontSize="lg" ml="4" whiteSpace="pre-line">
        {i18n.t("terms_limitations_text")}
      </Text>
      <Heading as="h2" fontSize="xl" fontWeight="bold" mt="4">
        <Text>{i18n.t("terms_user_rules_little")}</Text>
      </Heading>
      <Text fontSize="lg" ml="4" whiteSpace="pre-line">
        {i18n.t("terms_user_rules_text")}
      </Text>
      <Heading as="h2" fontSize="xl" fontWeight="bold" mt="4">
        <Text>{i18n.t("terms_prerogatives_little")}</Text>
      </Heading>
      <Text fontSize="lg" ml="4" whiteSpace="pre-line">
        {i18n.t("terms_prerogatives_text")}
      </Text>
      <Heading as="h2" fontSize="xl" fontWeight="bold" mt="4">
        <Text>{i18n.t("terms_user_access_little")}</Text>
      </Heading>
      <Text fontSize="lg" ml="4" whiteSpace="pre-line">
        {i18n.t("terms_user_access_text")}
      </Text>
      <Heading as="h2" fontSize="xl" fontWeight="bold" mt="4">
        <Text>{i18n.t("terms_service_products_little")}</Text>
      </Heading>
      <Text fontSize="lg" ml="4" whiteSpace="pre-line">
        {i18n.t("terms_service_products_text")}
      </Text>
      <Heading as="h2" fontSize="xl" fontWeight="bold" mt="4">
        <Text>{i18n.t("terms_support_little")}</Text>
      </Heading>
      <Text fontSize="lg" ml="4">
        {i18n.t("terms_support_text")}
      </Text>
      <Heading as="h2" fontSize="xl" fontWeight="bold" mt="4">
        <Text>{i18n.t("terms_privacy_policy_little")}</Text>
      </Heading>
      <Text fontSize="lg" ml="4" whiteSpace="pre-line">
        {i18n.t("terms_privacy_policy_text")}
      </Text>
      <Heading as="h2" fontSize="xl" fontWeight="bold" mt="4">
        <Text>{i18n.t("terms_amendments_little")}</Text>
      </Heading>
      <Text fontSize="lg" ml="4" whiteSpace="pre-line">
        {i18n.t("terms_amendments_text")}
      </Text>
    </Box>
  );
}

export default TermsAndConditions;
