import { track } from "@amplitude/analytics-browser";
import axios from "axios";
import { isMobile, isTablet } from "react-device-detect";
import { base_url } from "../constants/GlobalTypesAndEnumsAndConstants";

// For Amplitude tracking
export async function trackEvent(
  eventName: string,
  additionalEventProperties: any
) {
  let deviceType = "Desktop";
  if (isMobile) {
    deviceType = "Mobile";
  } else if (isTablet) {
    deviceType = "Tablet";
  }

  const eventTrackingProperties = {
    ...additionalEventProperties,
    platform: "Web",
    deviceType: deviceType,
  };

  track(eventName, eventTrackingProperties);
}


export const consumeApi = (endPoint: string, method: 'GET' | 'POST', body?: any): Promise<any> => {
  const options = {
    method: method,
    headers: {
      "Content-Type": "application/json"
    },
    data: body,
    url: `${base_url}/${endPoint}`,
  };
  return new Promise((resolve, reject) => {
    axios(options)
      .then(response => resolve(response.data))
      .catch(error => reject(error));
  });
};

export function gereateNumberFromText(text: any) {
  if (!text) return 0
  let firstLetter = text[0]
  let number = firstLetter.charCodeAt(0) - 97
  return (number < 0 ? number * -1 : number) % 10
}

export const arrayElementLooper = (snapshot: any) => {
  let data: any = [];
  snapshot.forEach(doc => {
    data.push({
      ...doc.data(),
      id: doc.id
    })
  })
  return data;
}