import {
    addDoc,
    collection,
    deleteDoc,
    doc,
    getDoc,
    getDocs,
    limit,
    onSnapshot,
    orderBy,
    query,
    setDoc,
    where,
} from "firebase/firestore";
import { db } from "../config/firebase";
import { FILES_TABLE_NAME, INCIDENCE_TABLE_NAME, MATCH_TABLE_NAME, NOTIFICATIONS_TABLE_NAME, TEAM_TABLE_NAME, TOURNAMENT_TABLE_NAME, USER_TABLE_NAME } from "./ModelDefinition";
import { arrayElementLooper } from "../utils/tools";
import { v4 as uuidv4 } from 'uuid';


export const saveOrUpdateUser = async (userId: string, data: any) => {
    let returnData: any = await setDoc(doc(db, USER_TABLE_NAME, userId), data, { merge: true })
        .then(() => {
            console.log("User has been updatedd");
            returnData = true
        })
        .catch((err) => {
            console.log(err);
        });
    // if (data.firstname && data.lastname && data.username)
    //     setupIndexSearchUserByUserId(userId)
    return returnData;
};

export const getUserByUsername = async (username: string) => {
    const usersCollectionRef = collection(db, USER_TABLE_NAME);
    const q = query(usersCollectionRef, where("username", "==", convertToUserName(username)));
    const data = await getDocs(q);
    return data.docs;
};

export const getUserByEmail = async (email: string) => {
    const usersCollectionRef = collection(db, USER_TABLE_NAME);
    const q = query(usersCollectionRef, where("email", "==", email.toLowerCase()));
    const data = await getDocs(q);
    return arrayElementLooper(data.docs);

};

export const getUserById = async (userId: string) => {
    const postDoc = doc(db, USER_TABLE_NAME, userId);
    let returnData = null;
    await getDoc(postDoc)
        .then((snapshot: any) => {
            console.log(snapshot.exists())
            if (snapshot.exists()) {
                returnData = snapshot.data();
                returnData.id = snapshot.id;
            }
        })
        .catch((err) => {
            return null;
        });
    return returnData;
};

const convertToUserName = (username: string) => {
    username = username.toLowerCase();
    return username
}


export const getUsers = async () => {
    const usersCollectionRef = collection(db, USER_TABLE_NAME);
    const q = query(usersCollectionRef, limit(30));
    const data = await getDocs(q);
    return arrayElementLooper(data.docs);

};


export const getOnlyUsers = async () => {
    const usersCollectionRef = collection(db, USER_TABLE_NAME);
    const q = query(usersCollectionRef, where("type", "!=", "team"), limit(30));
    const data = await getDocs(q);
    return arrayElementLooper(data.docs);

};

export const searchUsers = async (search: string) => {
    let searchString = search.toLowerCase().split(" ").join("")
    searchString = searchString.split("@").join("")
    const userSearchCollectionRef = collection(db, USER_TABLE_NAME);
    const q = query(userSearchCollectionRef, where("search_index", "array-contains", searchString), limit(50));
    const data = await getDocs(q);
    let dataArray = arrayElementLooper(data.docs);
    return dataArray;
};


export const searchOnlyUsers = async (search: string) => {
    let searchString = search.toLowerCase().split(" ").join("")
    searchString = searchString.split("@").join("")
    const userSearchCollectionRef = collection(db, USER_TABLE_NAME);
    const q = query(userSearchCollectionRef, where("type", "!=", "team"), where("search_index", "array-contains", searchString), limit(10));
    const data = await getDocs(q);
    let dataArray = arrayElementLooper(data.docs);
    return dataArray;
};


export const setupIndexUsers = async () => {
    const usersCollectionRef = collection(db, USER_TABLE_NAME);
    const q = query(usersCollectionRef, limit(30));
    const data = await getDocs(usersCollectionRef);
    let dataArray = arrayElementLooper(data.docs);
    for (let user of dataArray) {
        let indexSet: any = new Set();
        if (user.firstname && user.lastname) {
            let itemsToIndex = [user.firstname, user.lastname, `${user.firstname}${user.lastname}`];
            for (let i = 0; i < itemsToIndex.length; i++) {
                for (let y = 1; y < itemsToIndex[i].length + 1; y++) {
                    indexSet.add(itemsToIndex[i].substring(0, y).toLowerCase());
                }
            }
            await setDoc(doc(db, USER_TABLE_NAME, user.id), { search_index: [...indexSet] }, { merge: true })

        }

        if (user.firstname && user.lastname && user.username) {
            let itemsToIndex = [user.firstname, user.lastname, user.username, `${user.firstname}${user.lastname}${user.username}`];
            for (let i = 0; i < itemsToIndex.length; i++) {
                for (let y = 1; y < itemsToIndex[i].length + 1; y++) {
                    indexSet.add(itemsToIndex[i].substring(0, y).toLowerCase());
                }
            }
            await setDoc(doc(db, USER_TABLE_NAME, user.id), { search_index: [...indexSet] }, { merge: true })

        }

    }
};

export const setupIndexSearchUserByUserId = async (userId: string) => {
    let user: any = await getUserById(userId)
    let indexSet: any = new Set();
    let itemsToIndex = [user.firstname, user.lastname, user.username, `${user.firstname}${user.lastname}${user.username}`];
    for (let i = 0; i < itemsToIndex.length; i++) {
        for (let y = 1; y < itemsToIndex[i].length + 1; y++) {
            indexSet.add(itemsToIndex[i].substring(0, y).toLowerCase());
        }
    }
    await setDoc(doc(db, USER_TABLE_NAME, userId), { search_index: [...indexSet] }, { merge: true })
};

export const cancelIndexSearchUserByUserId = async (userId: string) => {
    await setDoc(doc(db, USER_TABLE_NAME, userId), { search_index: [], username: "removeduser_" + uuidv4().toString() }, { merge: true })
};




export const userStateChanged = (userId: string, callbackFunction: any) => {
    const usersCollectionRef = doc(db, USER_TABLE_NAME, userId);
    onSnapshot(usersCollectionRef, (data) => {
        callbackFunction(data.data())
    });

}

export const addNotificationToUser = async (userId: string, data: any) => {
    data.status = "sended"
    data.created_at = new Date()
    const docRef = await addDoc(collection(db, `${USER_TABLE_NAME}/${userId}/${NOTIFICATIONS_TABLE_NAME}`), data);
    return docRef
}


export const updateNotificationToUser = async (notificationId: string, data: any, userId: string) => {
    await setDoc(doc(db, `${USER_TABLE_NAME}/${userId}/${NOTIFICATIONS_TABLE_NAME}`, notificationId),
        data, { merge: true })

}


export const cancelNotificationToUser = async (userId: string, notificationId: string) => {
    let returnData: any = false
    await setDoc(doc(db, `${USER_TABLE_NAME}/${userId}/${NOTIFICATIONS_TABLE_NAME}`, notificationId), { show_alert: false, status: "canceled" }, { merge: true })
        .then(() => {
            console.log("Notification has been updatedd");
            returnData = true
        })
        .catch((err) => {
            console.log(err);
        });

    return returnData;
}


export const getNotificationFromUser = async (userId: string) => {
    const usersCollectionRef = collection(db, `${USER_TABLE_NAME}/${userId}/${NOTIFICATIONS_TABLE_NAME}`);
    const q = query(usersCollectionRef, where("show_alert", "==", true), orderBy("created_at", "desc"));
    // const q = query(usersCollectionRef, orderBy("created_at", "desc"));
    const data = await getDocs(q);
    return arrayElementLooper(data.docs);

}


export const setNotificationsToRead = async (userId: string) => {
    const usersCollectionRef = collection(db, `${USER_TABLE_NAME}/${userId}/${NOTIFICATIONS_TABLE_NAME}`);
    const q = query(usersCollectionRef, where("pending_to_read", "==", true));
    const data = await getDocs(q);
    let dataArray = arrayElementLooper(data.docs);
    for (let notification of dataArray) {
        await setDoc(doc(db, `${USER_TABLE_NAME}/${userId}/${NOTIFICATIONS_TABLE_NAME}`, notification.id), { pending_to_read: false }, { merge: true })
    }
    return dataArray;
}
export const getFilesUser = async (userId: string) => {
    const usersCollectionRef = collection(db, `${USER_TABLE_NAME}/${userId}/${FILES_TABLE_NAME}`);
    const q = query(usersCollectionRef, orderBy("created_at", "desc"));
    const data = await getDocs(q);
    return arrayElementLooper(data.docs);
};



export const getAllUserss = async () => {
    const usersCollectionRef = collection(db, `${USER_TABLE_NAME}`);
    const data = await getDocs(usersCollectionRef);
    let users = arrayElementLooper(data.docs);
    for (let user of users) {
        await saveOrUpdateUser(user.id, { updated_at: new Date() })
    }
    return
};



export const getFilesUserById = async (userId: string, fileId: string) => {
    const postDoc = doc(db, `${USER_TABLE_NAME}/${userId}/${FILES_TABLE_NAME}`, fileId);
    let returnData = null;
    await getDoc(postDoc)
        .then((snapshot: any) => {
            if (snapshot.exists()) {
                returnData = snapshot.data();
                returnData.id = snapshot.id;
            }
        })
        .catch((err) => {
            console.log(err);
            return null;
        });
    return returnData;
};


export const createFileUser = async (userId: string, data: any) => {
    data.created_at = new Date()
    if (data.id) {
        const docRef = await addDoc(collection(db, `${USER_TABLE_NAME}/${userId}/${FILES_TABLE_NAME}`), data);
        return docRef
    } else {
        await setDoc(doc(db, `${USER_TABLE_NAME}/${userId}/${FILES_TABLE_NAME}`, data.id),
            data, { merge: true })
    }

};

export const deleteFileUser = async (userId: string, fileId: string) => {
    const commentDoc = doc(db, `${USER_TABLE_NAME}/${userId}/${FILES_TABLE_NAME}`, fileId);
    await deleteDoc(commentDoc)
        .then(() => {
            console.log("Comment has been deleted");
        })
        .catch((err) => {
            console.log(err);
        });

};



export const getNumberOfTournamentByUser = async (userId: string) => {
    try {
        const usersCollectionRef = collection(db, `${USER_TABLE_NAME}/${userId}/${TOURNAMENT_TABLE_NAME}`);
        let snap = await getDocs(usersCollectionRef)
        return snap?.size;
    } catch (e) {
        console.log(e)
    }
    return 0

};

export const getNumberOfTeamsByUser = async (userId: string) => {
    try {
        const usersCollectionRef = collection(db, `${USER_TABLE_NAME}/${userId}/${TEAM_TABLE_NAME}`);
        let snap = await getDocs(usersCollectionRef)
        return snap?.size;
    } catch (e) {
        console.log(e)
    }
    return 0
};


export const addIncidenceToUser = async (userId: string, data: any, matchId: string, incidenceId: string) => {
    await setDoc(doc(db, `${USER_TABLE_NAME}/${userId}/${MATCH_TABLE_NAME}/${matchId}/${INCIDENCE_TABLE_NAME}`, incidenceId),
        data, { merge: true })

}


export const deleteIncidenceToUser = async (userId: string, data: any, matchId: string, incidenceId: string) => {
    const commentDoc = doc(db, `${USER_TABLE_NAME}/${userId}/${MATCH_TABLE_NAME}/${matchId}/${INCIDENCE_TABLE_NAME}`, incidenceId);
    await deleteDoc(commentDoc)
        .then(() => {
            console.log("Incidence user  has been deleted");
        })
        .catch((err) => {
            console.log(err);
        });
}


