import { create } from "zustand";


// ***** PAGE SETUP ***** //

export interface matchStoreState {
    currentMatch: any;
    setCurrentMatch: (match: any) => void;

    incidences: any[];
    setIncidences: (incidences: any[]) => void;

    timePlayed: {
        labelData: string,
        timePlayed: number
    };
    setTimePlayed: (labelData: string, timePlayed: number) => void;
}

export const matchStore = create<matchStoreState>((set) => ({
    currentMatch: null,
    setCurrentMatch: (match: any) => set({ currentMatch: match }),

    incidences: [],
    setIncidences: (incidences: any[]) => set({ incidences: incidences }),

    timePlayed: {
        labelData: "00:00",
        timePlayed: 0
    },
    setTimePlayed: (labelData: string, timePlayed: number) => set({ timePlayed: { labelData, timePlayed } })


}));

export interface appStoreState {
    userData: null;
    setUserData: (user: any) => void;

    isSideMenuOpen: boolean;
    setIsSideMenuOpen: (isOpen: boolean) => void;

    currentTournament: any;
    setCurrentTournament: (team: any) => void;

    currentTeam: any;
    setCurrentTeam: (team: any) => void;

    showTopBar: boolean;
    setShowTopBar: (show: boolean) => void;

    showBottomBar: boolean;
    setShowBottomBar: (show: boolean) => void;

    postsFeed: any[];
    setPostsFeed: (posts: any[]) => void;

    pushToken: string | null;
    setPushToken: (token: string) => void;

    showLoadingScreen: boolean | null;
    setShowLoadingScreen: (show: boolean) => void;
};

export const appStore = create<appStoreState>((set) => ({
    userData: null,
    setUserData: (user: any) => {
        set({ userData: user });
    },

    isSideMenuOpen: false,
    setIsSideMenuOpen: (isOpen: boolean) => set({ isSideMenuOpen: isOpen }),

    currentTournament: null,
    setCurrentTournament: (tournament: any) => set({ currentTournament: tournament }),

    currentTeam: null,
    setCurrentTeam: (team: any) => set({ currentTeam: team }),

    showTopBar: true,
    setShowTopBar: (show: boolean) => set({ showTopBar: show }),

    showBottomBar: true,
    setShowBottomBar: (show: boolean) => set({ showBottomBar: show }),

    postsFeed: [],
    setPostsFeed: (posts: any[]) => set({
        postsFeed: posts
    }),

    pushToken: null,
    setPushToken: (token: string) => set({ pushToken: token }),

    showLoadingScreen: null,
    setShowLoadingScreen: (show: boolean) => set({ showLoadingScreen: show }),
}));


type PostStoreState = {
    isCreatingPost: boolean;
    percentajeUploaded: number;
    postCreating: any;
    existErrorCreatingPost: boolean;
    setExistErrorCreatingPost: (existError: boolean) => void;
    showCommentsModal: boolean;
    setShowCommentsModal: (show: boolean) => void;
    selectedPost: any;
    setSelectedPost: (post: any) => void;
};

export const postStore = create<PostStoreState>((set) => ({
    isCreatingPost: false,
    percentajeUploaded: 0,
    postCreating: null,
    existErrorCreatingPost: false,
    setExistErrorCreatingPost: (existError: boolean) => set({ existErrorCreatingPost: existError }),
    showCommentsModal: false,
    setShowCommentsModal: (show: boolean) => set({ showCommentsModal: show }),
    selectedPost: null,
    setSelectedPost: (post: any) => set({ selectedPost: post })

}));
