import { Box, Image, Stack, Text } from "@chakra-ui/react";
import { useNavigation } from "react-router-dom";
import ImageFromText from "./ImageFromText";
import { matchStatusSpanish } from "../constants/GlobalTypesAndEnumsAndConstants";
import i18n from "../i18n/i18n";

interface ItemSelectSelectProps {
    onClick: () => void;
    match: any;
}


export default function MatchItem({ onClick, match }: ItemSelectSelectProps) {
    const navigation = useNavigation();

    return (
        <Box
            onClick={onClick}
            cursor="pointer"
            borderWidth="1px"
            borderColor="gray.200"
            borderRadius="md"
            backgroundColor="gray.50"
            p={5}
            mb={4}
        >
            <Box
                display="flex"
                alignItems="center"
                gap={4}
                flexDirection="row"
                justifyContent="space-between"
            >
                <Box display="flex" alignItems="center" gap={2} flex="1">
                    {match?.team_home_image_url ? (
                        <Image
                            src={match?.team_home_image_url}
                            boxSize="25px"
                            borderRadius="full"
                        />
                    ) : (
                        <ImageFromText
                            // size="md"
                            text={`${match.team_home ?? ""} `}
                        />
                    )}
                    <Text fontWeight="semibold" fontSize="xs" color="black">
                        {match.tournamnet_name}
                    </Text>
                </Box>
                {match.status && (
                    <Box
                        display="flex"
                        px={2}
                        py={1}
                        justifyContent="center"
                        alignItems="center"
                        borderRadius="md"
                        backgroundColor="blue.500"
                    >
                        <Text fontWeight="semibold" color="white" fontSize="xx-small">
                            {matchStatusSpanish[match.status] ?? ""}
                        </Text>
                    </Box>
                )}
            </Box>

            <Box display="flex" alignItems="center" gap={4} flexDirection="row" mt={4}>
                <Stack alignItems="center" gap={2} flex="1" flexDirection="row">
                    <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                        gap={1}
                        flex="1"
                        backgroundColor="gray.50"
                    >
                        {match?.first_team_media_url ? (
                            <Image
                                src={match.first_team_media_url}
                                boxSize="25px"
                                borderRadius="full"
                            />
                        ) : (
                            <ImageFromText
                                // size="md"
                                text={`${match.first_team_name ?? ""} `}
                            />
                        )}
                        <Text color="gray.400" fontSize="sm">
                            {match.first_team_name ?? ""}
                        </Text>
                    </Box>
                    <Box
                        display="flex"
                        width="50px"
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                        borderRadius="md"
                        backgroundColor="white"
                    >
                        <Text color="gray.400" fontSize="lg">
                            {match?.goals_first_team ?? ""}
                        </Text>
                    </Box>
                </Stack>
                <Text fontWeight="semibold" color="black" fontSize="lg">
                    -
                </Text>
                <Stack alignItems="center" gap={2} flex="1" flexDirection="row">
                    <Box
                        display="flex"
                        width="50px"
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                        borderRadius="md"
                        backgroundColor="white"
                    >
                        <Text color="gray.400" fontSize="lg">
                            {match.goals_second_team ?? ""}
                        </Text>
                    </Box>
                    <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                        gap={1}
                        flex="1"
                        backgroundColor="gray.50"
                    >
                        {match?.second_team_media_url ? (
                            <Image
                                src={match.second_team_media_url}
                                boxSize="25px"
                                borderRadius="full"
                            />
                        ) : (
                            <ImageFromText
                                // size="md"
                                text={`${match.second_team_name ?? ""} `}
                            />
                        )}
                        <Text color="gray.400" fontSize="sm">
                            {match.second_team_name ?? ""}
                        </Text>
                    </Box>
                </Stack>
            </Box>

            <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                backgroundColor="gray.50"
                mt={4}
            >
                <Box
                    display="flex"
                    alignItems="center"
                    gap={2}
                    flexDirection="row"
                    mb={2}
                >
                    <Text color="gray.400" fontSize="xx-small">
                        {match?.day
                            ? `${i18n.t('date')}: ${i18n.t(match.day)}/${match.number_day} ${i18n.t(match.month)}`
                            : `${i18n.t('date')}: ${i18n.t('undefined')}`}
                    </Text>
                    {/* <IconClose /> */}
                    <Text color="gray.400" fontSize="xx-small">
                        {match?.hour
                            ? `${i18n.t('hour')}: ${match.hour}:${match.minuts}`
                            : `${i18n.t('hour')}: ${i18n.t('undefined')}`}
                    </Text>
                </Box>
                <Text color="gray.400" fontSize="xx-small">
                    {match?.field_name
                        ? `${i18n.t('place')}: ${match.field_name}`
                        : `${i18n.t('place')}: ${i18n.t('undefined')}`}
                </Text>
            </Box>
        </Box>
    );
}