import React from 'react';
import { useMediaQuery, Box, Text, Flex, Image, Avatar } from '@chakra-ui/react';
import { colors } from '../../constants/GlobalStyles'
import { AuthorProps } from '../../constants/GlobalTypesAndEnumsAndConstants';
import i18n from "../../i18n/i18n";
import { EllipseIcon, PublicIcon } from '../../constants/IconsComponent';

function PostAuthor({ post }: AuthorProps) {
    const [isMobile] = useMediaQuery('(max-width: 992px)');

    return (
        <>
            <Flex gap={2} p={isMobile ? "2" : "0"}>
                <Box>
                    { post.user_profile_image 
                    ? 
                    <Image
                        boxSize="40px"
                        borderRadius="100%"
                        src={ post.user_profile_image  }
                    />
                    :
                      <Avatar 
                        boxSize="40px"
                        borderRadius="100%"
                        name={ post.firstname + post.lastname }
                      />
                    }
                </Box>
                <Box>
                    <Box>
                        <Text
                            fontSize="13px"
                            fontWeight="600"
                            lineHeight="18.51px"
                            color={colors.figmaColors.black}
                        >
                            {post.firstname}{post.lastname}
                        </Text>
                    </Box>
                    <Box>
                        <Flex gap={2} align="center" justify="center">
                            <Text
                                fontSize="11px"
                                fontWeight="400"
                                lineHeight="15.66px"
                                color={colors.figmaColors.grey3}
                            >
                                {post.created_minuts < 60
                                    ? `${i18n.t('posted on')} ${post.created_minuts} ${i18n.t('minutes')}`
                                    : post.created_hours < 48
                                        ? `${i18n.t('posted on')} ${post.created_hours} ${i18n.t('hours')}`
                                        : post.created_days < 30
                                            ? `${i18n.t('posted on')} ${post.created_days} ${i18n.t('days')}`
                                            : `${i18n.t('posted on')} ${post.date_format}`}

                            </Text>
                            <EllipseIcon />
                            <PublicIcon />
                        </Flex>
                    </Box>
                </Box>
            </Flex>
        </>
    )
};

export default PostAuthor;