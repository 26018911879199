import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import HomePage from "./pages/HomePage";
import LoginPage from "./pages/login/LoginPage";
import RegisterPage from "./pages/login/RegisterPage";
import RecoverPage from "./pages/login/RecoverPage";
import HeaderComponent from "./components/HeaderComponent";
import TermsAndConditions from "./pages/TermsAndConditions";
import YoutubeVideos from "./pages/YoutubeVideos";
import TournamentPage from "./pages/tournaments/TournamentPage";

function AppRouter() {
  return (
    <Routes>
      <Route path="/login" element={<LoginPage />} />
      <Route path="/register" element={<RegisterPage />} />
      <Route path="/recover_password" element={<RecoverPage />} />
      <Route
        path="/*"
        element={
          <HeaderComponent>
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route
                path="/terms_and_conditions"
                element={<TermsAndConditions />}
              />
              <Route
                path="/youtube_video/:videoId"
                element={<YoutubeVideos />}
              />
              <Route
                path="/tournament/:tournamentName"
                element={<TournamentPage />}
              />
                <Route
                    path="/*"
                    element={
                        <>
                            {
                                <HeaderComponent>
                                    <Routes>
                                        <Route path="/" element={<HomePage />} />
                                        <Route path="/terms_and_conditions" element={<TermsAndConditions />} />
                                        <Route path="/youtube_video/:videoId" element={<YoutubeVideos />} />
                                        <Route path="/tournament/:tournamentId" element={<TournamentPage />} />
                                    </Routes>
                                </HeaderComponent>
                            }
                        </>
                    }
                />
            </Routes>
          </HeaderComponent>
        }
      />
    </Routes>
  );
}
export default React.memo(AppRouter);
