import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";
import { getFunctions } from "firebase/functions";

// PRODUCTION
export const extra = {
    firebaseApiKey: "AIzaSyCAE9m49TXQUK1KdpGaakvlb5Rl1R3zy0Q",
    firebaseAuthDomain: "wuamball.firebaseapp.com",
    firebaseProjectId: "wuamball",
    firebaseStorageBucket: "wuamball.appspot.com",
    firebaseMessagingSenderId: "51645330577",
    firebaseAppId: "1:51645330577:web:71b0511bc3db816d2e7b30",
    "eas": {
        "projectId": "9066b885-be6a-4ec3-b38b-b585d9f9f2e3"
    },
    databaseURL: "https://wuamball-default-rtdb.firebaseio.com",
    webClientId: '51645330577-02el6950rbak2ksg8knllbdageot6ot6.apps.googleusercontent.com',
}

// DEVELOPMENT
// let extra = {
//     firebaseApiKey: "AIzaSyABQS7gwV97AAxkWXZaTf3EVWGKIPgF9nk",
//     firebaseAuthDomain: "wuamball-e1ce9.firebaseapp.com",
//     firebaseProjectId: "wuamball-e1ce9",
//     firebaseStorageBucket: "wuamball-e1ce9.appspot.com",
//     firebaseMessagingSenderId: "180615273203",
//     firebaseAppId: "1:180615273203:web:27e867c0812a6a1a8b7586",
//     databaseURL: "https://wuamball-e1ce9-default-rtdb.firebaseio.com",
//     webClientId: '180615273203-6toia2bsjvshpo8tqj647rmn3omj0jlb.apps.googleusercontent.com',
// }

const firebaseConfig = {
    apiKey: extra?.firebaseApiKey,
    authDomain: extra?.firebaseAuthDomain,
    projectId: extra?.firebaseProjectId,
    storageBucket: extra?.firebaseStorageBucket,
    messagingSenderId: extra?.firebaseMessagingSenderId,
    appId: extra?.firebaseAppId,
    databaseURL: extra?.databaseURL,
};

const app = initializeApp(firebaseConfig);

const db = getFirestore(app);
const storage = getStorage(app);
const auth = getAuth(app);
const functions = getFunctions(app);
export { db, storage, auth, functions };
