import React from "react";
import { Box, Text } from "@chakra-ui/react";
import { colors } from "../constants/GlobalStyles";
import { BackgroundColorsProfile } from "../constants/GlobalTypesAndEnumsAndConstants";
import { gereateNumberFromText } from "../utils/tools";

interface ImageFromTextProps {
    text?: string;
    sizeIcon?: React.CSSProperties;
    sizeText?: string;
}

export default function ImageFromText({
    text,
    sizeIcon,
    sizeText = "extraSmall",
}: ImageFromTextProps) {

    const getText = () => {
        if (!text) return "";
        let textShow = "";
        for (let letter of text.split(" ")) {
            textShow = textShow.concat(letter.charAt(0).toUpperCase());
        }
        return textShow.substring(0, 2);
    };

    const backgroundColor = Object.values(BackgroundColorsProfile)[gereateNumberFromText(getText())];

    return (
        <Box
            sx={sizeIcon ? sizeIcon : styles.roundIcon}
            bg={backgroundColor}
            __css={styles.contentText}
        >
            <Text
                fontWeight="bold"
                fontSize={sizeText}
                color={colors.text.white}
                mt={1}
            >
                {getText()}
            </Text>
        </Box>
    );
}

const styles = {
    roundIcon: {
        borderRadius: "50%",
        width: "30px",
        height: "30px",
    },
    contentText: {
        borderRadius: "50%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
    },
};
