import { Button, Flex, useToast } from "@chakra-ui/react";
import { MetaIcon, GoogleIcon, AppleIcon } from "../constants/IconsComponent";
import { auth } from "../config/firebase";
import { useNavigate } from "react-router-dom";
import {
  GoogleAuthProvider,
  FacebookAuthProvider,
  signInWithPopup,
  OAuthProvider,
} from "firebase/auth";
import i18n from "../i18n/i18n";

const LoginOptionsGrid = ({ showMeta, showGoogle, showApple }) => {
  const toast = useToast();
  const navigate = useNavigate();
  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);

  const getSignInErrorMessage = (error) => {
    if (error.code) {
      switch (error.code) {
        case "auth/popup-closed-by-user":
          return i18n.t("error_cancel");
        case "auth/popup-blocked":
          return i18n.t("error_popup");
        case "auth/network-request-failed":
          return i18n.t("error_network");
        default:
          return i18n.t("error_unknown");
      }
    }
    return i18n.t("error_not_related");
  };

  const handleSignInWithMeta = () => {
    // Implement Meta sign-in logic
  };

  const handleSignInWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      console.log("Google sign-in successful:", result.user);
      toast({
        title: i18n.t("sign_success"),
        description: i18n.t("sign_google"),
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      navigate("/");
    } catch (error) {
      console.error("Error signing in with Google:", error);
      toast({
        title: i18n.t("sign_error"),
        description: getSignInErrorMessage(error),
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleSignInWithApple = async () => {
    const provider = new OAuthProvider("apple.com");
    try {
      const result = await signInWithPopup(auth, provider);
      console.log("Apple sign-in successful:", result.user);
      toast({
        title: i18n.t("sign_success"),
        description: i18n.t("sign_apple"),
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      navigate("/");
    } catch (error) {
      console.error("Error signing in with Apple:", error);
      toast({
        title: i18n.t("sign_error"),
        description: getSignInErrorMessage(error),
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Flex direction="row" gap="10px" align="center" justify="center">
      {showMeta && (
        <Button variant="unstyled" height="auto" onClick={handleSignInWithMeta}>
          <MetaIcon />
        </Button>
      )}

      {showGoogle && (
        <Button
          variant="unstyled"
          height="auto"
          onClick={handleSignInWithGoogle}
        >
          <GoogleIcon />
        </Button>
      )}

      {showApple && isIOS && (
        <Button
          variant="unstyled"
          height="auto"
          onClick={handleSignInWithApple}
        >
          <AppleIcon />
        </Button>
      )}
    </Flex>
  );
};

export default LoginOptionsGrid;
