import React from 'react';
import { useMediaQuery, Flex, Box, Text, Image, Link } from '@chakra-ui/react';
import { colors } from '../../constants/GlobalStyles'
import { playStoreUrl, appStoreUrl } from '../../constants/GlobalTypesAndEnumsAndConstants'
import i18n from "../../i18n/i18n";
import ImageBg from '../imgs/smartphone.png'
import WuamballLogo from '../imgs/logo.png'
import Google from '../imgs/Google.png'
import Apple from '../imgs/Apple.png'
import { AppleStoreIcon, GooglePlayIcon, WuamballIcon } from '../../constants/IconsComponent';

interface ChildProps {
    openPopUp: () => void;
}

const PopUpComponent: React.FC<ChildProps> = ({ openPopUp }) => {
    const [isMobile] = useMediaQuery('(max-width: 992px)');

    const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
        // if (e.target === e.currentTarget && e.target.id === 'modal') {
        //     openPopUp();
        // }
        const target = e.target as HTMLDivElement;
        if (target.id === 'modal') {
            openPopUp();
        }
    }

    return (
        <>
            <Flex
                id="modal"
                width="100%"
                height="100%"
                overflow={"auto"}
                position={"fixed"}
                top={0}
                left={0}
                zIndex={999}
                justify={"center"}
                align={"center"}
                onClick={handleClick}
            >
                <Flex
                    width={isMobile ? "350px" : "795px"}
                    height={isMobile ? "562px" : "500px"}
                    bg={colors.surface.white}
                    borderRadius={"20px"}
                    overflow={'hidden'}
                    boxShadow={"0px 4px 10px rgba(0, 0, 0, 0.1)"}
                    flexDirection={isMobile ? "column" : "row"}
                >
                    <Box
                        flex={1}
                        bgImage={ImageBg}
                        bgSize="cover"
                        bgPosition="right center"
                    >
                    </Box>

                    <Box
                        p={5}
                        flex={1}
                    >
                        <Flex
                            flexDirection={"column"}
                            marginTop={isMobile ? "10px" : "40px"}
                        >
                            <Box>
                                <WuamballIcon width="98px" height="33px" />
                            </Box>
                            <Box>
                                <Text
                                    marginTop={isMobile ? "10px" : "30px"}
                                    fontWeight={"300"}
                                    fontSize={isMobile ? "22px" : "34px"}
                                    lineHeight={isMobile ? "31.33px" : "48.42px"}
                                    color={colors.figmaColors.secColor}
                                >
                                    {i18n.t('pop up text 1')}
                                </Text>
                                <Text
                                    marginTop={isMobile ? "10px" : "20px"}
                                    fontWeight={"400"}
                                    fontSize={"17px"}
                                    lineHeight={"24.21px"}
                                    color={colors.figmaColors.black}
                                >
                                    {i18n.t('pop up text 2')}
                                </Text>
                                <Text
                                    marginTop={isMobile ? "10px" : "20px"}
                                    fontSize={"13px"}
                                    lineHeight={"18.51px"}
                                    fontWeight={"500"}
                                    color={colors.figmaColors.grey3}
                                >
                                    {i18n.t('pop up text 3')}
                                </Text>
                            </Box>
                            <Box>
                                <Flex
                                    gap={2}
                                    marginTop={"40px"}
                                    align={"center"}
                                    justify={"center"}
                                >
                                    <Link target='_blank' href={playStoreUrl}>
                                        <GooglePlayIcon />
                                    </Link>
                                    <Link target='_blank' href={appStoreUrl}>
                                        <AppleStoreIcon />
                                    </Link>
                                </Flex>
                            </Box>
                        </Flex>
                    </Box>
                </Flex>

            </Flex>


        </>
    );
}

export default PopUpComponent;
