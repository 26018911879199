import {
    collection,
    doc,
    getDoc,
    getDocs,
    limit,
    query,
    setDoc,
    deleteDoc, orderBy, addDoc, where
} from "firebase/firestore";
// import { arrayElementLooper } from "../assets/tools";
import { db } from "../config/firebase";
import { addTournamentToTeam, createUserForTeam, getTeamById, removeTecnhnicaStuff } from './TeamModel';
import { addNotificationToUser, getUserByUsername, saveOrUpdateUser, updateNotificationToUser } from "./UserModel";
// import uuid from "react-native-uuid";
// import { IncidenceTypes, TypeNotifications } from "../constants/GlobalTypesAndEnums";
import { APPLICATION_TABLE_NAME, GROUP_TABLE_NAME, MANAGERS_TABLE_NAME, MATCH_TABLE_NAME, PHASES_TABLE_NAME, PLAYERS_TABLE_NAME, RANKING_TABLE_NAME, TEAM_TABLE_NAME, TECHNICALSTAFF_TABLE_NAME, TOURNAMENT_TABLE_NAME, USER_TABLE_NAME } from "./ModelDefinition";
// import { Alert } from "react-native";
import { v4 as uuidv4 } from 'uuid';
import { arrayElementLooper } from "../utils/tools";
import i18n from "../i18n/i18n";
import { IncidenceTypes, TypeNotifications } from "../constants/GlobalTypesAndEnumsAndConstants";
export const createTournament = async (userId: string, data: any) => {
    data.created_at = new Date()
    data.user_id = userId
    if (!data.id) {
        data.id = uuidv4().toString();
    }
    await setDoc(doc(db, `${TOURNAMENT_TABLE_NAME}`, data.id), data, { merge: true })
    await setDoc(doc(db, `${USER_TABLE_NAME}/${userId}/${TOURNAMENT_TABLE_NAME}`, data.id),
        {
            created_at: new Date(),
            tournament_id: data.id,
            rol: "owner"
        }, { merge: true })
    await createUserForTournament(data)
    //creacion de usuario del torneo
    return data;
};

export const createUserForTournament = async (tournament: any) => {
    let username = tournament.name.toLowerCase().split(" ").join("").split("@").join("")
    const usersByUsername = await getUserByUsername(username)
    if (usersByUsername.length > 0) {
        username = `${username}${usersByUsername.length}`
    }
    let userToTeam: any = {
        username: username,
        firstname: tournament.name,
        lastname: "",
        id: tournament.id,
        type: "tournament"
    }
    if (tournament.media_url) {
        userToTeam.image_url = tournament.media_url
    }
    let tournamentData = {
        ...userToTeam,
        ...tournament
    };
    await saveOrUpdateUser(tournament.id, tournamentData)

}


export const updatateTournament = async (tournamentId: string, data: any) => {
    await setDoc(doc(db, `${TOURNAMENT_TABLE_NAME}`, tournamentId),
        data, { merge: true })
    if (data.media_url) {
        data.image_url = data.media_url
    }
    await saveOrUpdateUser(tournamentId, data)
    return data;
};


export const getTournamentById = async (tournamnetId: string) => {
    const postDoc = doc(db, TOURNAMENT_TABLE_NAME, tournamnetId);
    let returnData = null;
    await getDoc(postDoc)
        .then((snapshot: any) => {
            if (snapshot.exists()) {
                returnData = snapshot.data();
                returnData.id = snapshot.id;
            } else {
                return null;
            }
        })
        .catch((err) => {
            console.log(err);
            return null;
        });
    return returnData;
};


export const getTournamentsByUserId = async (userId: string) => {
    const tournamentsCollectionRef = collection(db, `${USER_TABLE_NAME}/${userId}/${TOURNAMENT_TABLE_NAME}`);
    const q = query(tournamentsCollectionRef, where("rol", "==", "owner"));
    const data = await getDocs(q);
    let tournamentsUser = arrayElementLooper(data.docs);
    return tournamentsUser
}


export const getCurrentTournamentsForTeam = async (teamId: string) => {
    const tournamentsCollectionRef = collection(db, `${TEAM_TABLE_NAME}/${teamId}/${TOURNAMENT_TABLE_NAME}`);
    // const q = query(tournamentsCollectionRef, where("status", "==", "actually"), orderBy("created_at", "desc"));
    const q = query(tournamentsCollectionRef, where("status_tournament", "==", "actually"));
    const data = await getDocs(q);
    let tournamentsUser = arrayElementLooper(data.docs);
    let tournamentsReturn = []
    for (let tournament of tournamentsUser) {
        let tournamentData = await getTournamentById(tournament.id)
        if (!tournamentData.deleted || tournamentData.deleted === "undefined") {
            tournamentsReturn.push(tournamentData)
        }

    }
    return tournamentsReturn
}

export const getHistoryTournamentsForTeam = async (teamId: string) => {
    const tournamentsCollectionRef = collection(db, `${TEAM_TABLE_NAME}/${teamId}/${TOURNAMENT_TABLE_NAME}`);
    const q = query(tournamentsCollectionRef, where("status", "==", "finished"), orderBy("created_at", "desc"));
    const data = await getDocs(q);
    let tournamentsUser = arrayElementLooper(data.docs);
    let tournamentsReturn = []
    for (let tournament of tournamentsUser) {
        let tournamentData = await getTournamentById(tournament.tournament_id)
        tournamentsReturn.push(tournamentData)
    }
    return tournamentsReturn
}


export const getGroupFromTournamentByPhase = async (tournamentId: string, phaseId: string) => {
    const groupsCollectionRef = collection(db, `${TOURNAMENT_TABLE_NAME}/${tournamentId}/${PHASES_TABLE_NAME}/${phaseId}/${GROUP_TABLE_NAME}`);
    const groupsDocs = await getDocs(groupsCollectionRef);
    let groups = arrayElementLooper(groupsDocs.docs);
    return groups
}

export const getMatchFromTournamentByPhase = async (tournamentId: string, phaseId: string) => {
    const matchCollectionRef = collection(db, `${TOURNAMENT_TABLE_NAME}/${tournamentId}/${PHASES_TABLE_NAME}/${phaseId}/${MATCH_TABLE_NAME}`);
    // const q = query(matchCollectionRef, where("status", "!=", "cancel"));
    const matchsDocs = await getDocs(matchCollectionRef);
    let matchs = arrayElementLooper(matchsDocs.docs);
    return matchs
}

export const getMatchFromTournamentByPhaseAndGroup = async (tournamentId: string, phaseId: string, groupId: string) => {
    const matchCollectionRef = collection(db, `${TOURNAMENT_TABLE_NAME}/${tournamentId}/${PHASES_TABLE_NAME}/${phaseId}/${GROUP_TABLE_NAME}/${groupId}/${MATCH_TABLE_NAME}`);
    // const q = query(matchCollectionRef, orderBy("match_date", "asc"));
    const matchsDocs = await getDocs(matchCollectionRef);
    let matches = arrayElementLooper(matchsDocs.docs);
    let matchesWithUndefinedDate = matches.filter(a => a.match_date == undefined)
    let matchesDate = matches.filter(a => a.match_date != undefined)
    matchesDate = matchesDate.sort((a, b) => (a.match_date < b.match_date) ? -1 : 1)
    matches = [...matchesDate, ...matchesWithUndefinedDate]
    return matches
}


export const getMatchGroupByPhasesFromTournament = async (tournamentId: string) => {
    const phasesCollectionRef = collection(db, `${TOURNAMENT_TABLE_NAME}/${tournamentId}/${PHASES_TABLE_NAME}`);
    const q = query(phasesCollectionRef, orderBy("created_at", "asc"));
    const phasesDocs = await getDocs(q);
    let phases = arrayElementLooper(phasesDocs.docs);
    for (let phase of phases) {
        phase.groups = []
        if (phase.count_groups) {
            let groups = await getGroupFromTournamentByPhase(tournamentId, phase.id)
            for (let group of groups) {
                let matches = await getMatchFromTournamentByPhaseAndGroup(tournamentId, phase.id, group.id)
                let matchesWithUndefinedDate = matches.filter(a => a.match_date == undefined)
                let matchesDate = matches.filter(a => a.match_date != undefined)
                matchesDate = matchesDate.sort((a, b) => (a.match_date < b.match_date) ? -1 : 1)
                matches = [...matchesDate, ...matchesWithUndefinedDate]
                group.matchs = matches
            }
            phase.groups = groups
        } else {
            let matches = await getMatchFromTournamentByPhase(tournamentId, phase.id)
            let matchesWithUndefinedDate = matches.filter(a => a.match_date == undefined)
            let matchesDate = matches.filter(a => a.match_date != undefined)
            matchesDate = matchesDate.sort((a, b) => (a.match_date < b.match_date) ? -1 : 1)
            matches = [...matchesDate, ...matchesWithUndefinedDate]
            phase.matchs = matches
        }
    }
    return phases
}



export const getTeamsFromTournament = async (tournamentId: string) => {
    const teamsCollectionRef = collection(db, `${TOURNAMENT_TABLE_NAME}/${tournamentId}/${TEAM_TABLE_NAME}`);
    const data = await getDocs(teamsCollectionRef);
    let teamsIds = arrayElementLooper(data.docs);
    let teamsReturn = []
    for (let team of teamsIds) {
        let teamData = await getTeamById(team.team_id)
        if (teamData.deleted === undefined || teamData.delete === false) {
            teamsReturn.push(teamData)
        }
    }
    return teamsReturn
}

export const inviteTeam = async (userFrom: string, tournamentId: string, teamId: string, tournamentName: string, teamName: string) => {
    let teamOwner: any = await getTeamById(teamId)
    let notification = await addNotificationToUser(teamOwner.user_id, {
        created_at: new Date(),
        push_notification_sended: false,
        origin_user_id: userFrom,
        status: "sended",
        text: `${tournamentName} ${i18n.t('msgRequestParticipation')} ${teamName}`,
        title: `Invitacion de ${tournamentName}`,
        type: TypeNotifications.teamParticipation,
        show_alert: true,
        team_id: teamId,
        tournament_id: tournamentId,
        tournament_name: tournamentName,
        team_name: teamName,
        user_id: teamOwner.user_id
    })
    await setDoc(doc(db, `${TOURNAMENT_TABLE_NAME}/${tournamentId}/${APPLICATION_TABLE_NAME}`, notification.id),
        {
            created_at: new Date(),
            user_id_from: userFrom,
            user_id_to: teamOwner.user_id,
            tournament_id: tournamentId,
            team_id: teamId,
            status: "acepted",
            type: TypeNotifications.teamParticipation,
            notification_id: notification.id,
        }, { merge: true })
    await addTournamentToTeam(teamId, tournamentId)
    await setDoc(doc(db, `${TOURNAMENT_TABLE_NAME}/${tournamentId}/${TEAM_TABLE_NAME}`, teamId),
        {
            created_at: new Date(),
            team_id: teamId,
            team_suscribed: true
        }, { merge: true })

    return notification

}

export const getTeamInvitation = async (teamId: string, tournamentId: string) => {
    const teamsCollectionRef = collection(db, `${TOURNAMENT_TABLE_NAME}/${tournamentId}/${APPLICATION_TABLE_NAME}`);
    const q = query(teamsCollectionRef, where("type", "==", TypeNotifications.teamParticipation), where("team_id", "==", teamId));
    const data = await getDocs(q);
    let teamUser = arrayElementLooper(data.docs);
    let returnData = teamUser.length > 0 ? teamUser[0] : null;
    return returnData;

}

export const removeTournamentApplication = async (tournamentId: string, applicationId: string) => {
    const commentDoc = doc(db, `${TOURNAMENT_TABLE_NAME}/${tournamentId}/${APPLICATION_TABLE_NAME}`, applicationId);
    await deleteDoc(commentDoc)
        .then(() => {
            console.log("Applications has been deleted");
        })
        .catch((err) => {
            console.log(err);
        });

}

export const createPhasesTournamentWithNumPhases = async (tournamnetId: string, countPhases: number) => {
    let phases = []
    for (let i = 0; i < countPhases; i++) {
        let data = {
            id: `${i}_${tournamnetId}`,
            name: `Fase ${i + 1}`
        }
        let refPhase = await setDoc(doc(db, `${TOURNAMENT_TABLE_NAME}/${tournamnetId}/${PHASES_TABLE_NAME}`, `${i}_${tournamnetId}`), data, { merge: true })
        phases.push(refPhase)
    }
    return phases;

};
export const getPhasesByTournamentId = async (tournamnetId: string) => {
    const phasesCollectionRef = collection(db, `${TOURNAMENT_TABLE_NAME}/${tournamnetId}/${PHASES_TABLE_NAME}`);
    const q = query(phasesCollectionRef, orderBy("created_at"));
    const data = await getDocs(q);
    const phases = arrayElementLooper(data.docs)
    let phaseReturn = []
    for (let phase of phases) {
        if (!phase.deleted || phase.deleted === 'undefined')
            phaseReturn.push(phase)
    }
    return phaseReturn
};



export const updatatePhase = async (tournamentId: string, data: any) => {
    await setDoc(doc(db, `${TOURNAMENT_TABLE_NAME}`, tournamentId),
        data, { merge: true })
    return data;
};

export const deletePhase = async (phaseId: string, tournamentId: string, data: any) => {
    await setDoc(doc(db, `${TOURNAMENT_TABLE_NAME}/${tournamentId}/${PHASES_TABLE_NAME}`, phaseId),
        data, { merge: true })
};

export const updatePhase = async (phaseId: string, tournamentId: string, data: any) => {

    await setDoc(doc(db, `${TOURNAMENT_TABLE_NAME}/${tournamentId}/${PHASES_TABLE_NAME}`, phaseId),
        data, { merge: true })

};


export const createPhase = async (tournamentId: string, data: any, groups: any) => {
    data.created_at = new Date()
    data.deleted = false
    let ref = await addDoc(collection(db, `${TOURNAMENT_TABLE_NAME}/${tournamentId}/${PHASES_TABLE_NAME}`), data);
    if (data.have_groups) {
        for (let i = 0; i < groups.length; i++) {
            let dataGroup = {
                id: `${i}_${ref.id} `,
                name: groups[i].name,
                count_teams: data.count_teams_by_group
            }
            await setDoc(doc(db, `${TOURNAMENT_TABLE_NAME}/${tournamentId}/${PHASES_TABLE_NAME}/${ref.id}/${GROUP_TABLE_NAME}`, `${i}_${ref.id}`), dataGroup, { merge: true })

        }
    }
    return ref;
};

export const createGroup = async (tournamentId: string, phase: any, groupName: any, phaseId: string) => {
    const groupId = uuidv4().toString();
    let dataGroup = {
        id: groupId,
        name: groupName,
        count_teams: phase.count_teams_by_group
    }
    await setDoc(doc(db, `${TOURNAMENT_TABLE_NAME}/${tournamentId}/${PHASES_TABLE_NAME}/${phaseId}/${GROUP_TABLE_NAME}`, groupId), dataGroup, { merge: true })
    return dataGroup
};

export const editGroup = async (tournamentId: string, phase: any, groupName: any, phaseId: string, groupId: string) => {
    let dataGroup = {
        id: groupId,
        name: groupName,
        count_teams: phase.count_teams_by_group
    }
    await setDoc(doc(db, `${TOURNAMENT_TABLE_NAME}/${tournamentId}/${PHASES_TABLE_NAME}/${phaseId}/${GROUP_TABLE_NAME}`, groupId), dataGroup, { merge: true })
    return dataGroup
};

export const deleteGroup = async (tournamentId: string, phaseId: string, groupId: string) => {
    let groupRef = doc(db, `${TOURNAMENT_TABLE_NAME}/${tournamentId}/${PHASES_TABLE_NAME}/${phaseId}/${GROUP_TABLE_NAME}`, groupId);
    await deleteDoc(groupRef)
        .then(() => {
            console.log("Group has been deleted");
        })
        .catch((err) => {
            console.log(err);
        });
};



export const getGroupsByTournamentAndPhase = async (tournamentId: string, phaseId: string) => {
    const groupsCollectionRef = collection(db, `${TOURNAMENT_TABLE_NAME}/${tournamentId}/${PHASES_TABLE_NAME}/${phaseId}/${GROUP_TABLE_NAME}`);
    const groups = await getDocs(groupsCollectionRef);
    const sortedGroups = arrayElementLooper(groups.docs).sort((a, b) => {
        const nameA = a.name.toLowerCase();
        const nameB = b.name.toLowerCase();
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
    });

    return sortedGroups;
}


export const getPhasseFromTournamentByPhaseId = async (tournamentId: string, phaseId: string) => {
    const phaseDoc = doc(db, `${TOURNAMENT_TABLE_NAME}/${tournamentId}/${PHASES_TABLE_NAME}`, phaseId);
    let returnData = null;
    await getDoc(phaseDoc)
        .then((snapshot: any) => {
            if (snapshot.exists()) {
                returnData = snapshot.data();
                returnData.id = snapshot.id;
            } else {
                return null;
            }
        })
        .catch((err) => {
            console.log(err);
            return null;
        });
    return returnData;

}


export const verifyPlayerTeamTournamentInvitation = async (teamId: string, tournamentId: string, userId: string) => {
    const userDoc = doc(db, `${TEAM_TABLE_NAME}/${teamId}/${TOURNAMENT_TABLE_NAME}/${tournamentId}/${PLAYERS_TABLE_NAME}`, userId);
    const dataPlayesRef = await getDoc(userDoc);
    let returnData = dataPlayesRef.data();
    return returnData

};

export const verifyTechincalStaffTeamTournamentInvitation = async (teamId: string, tournamentId: string, userId: string) => {
    const userDoc = doc(db, `${TEAM_TABLE_NAME}/${teamId}/${TOURNAMENT_TABLE_NAME}/${tournamentId}/${TECHNICALSTAFF_TABLE_NAME}`, userId);
    const dataPlayesRef = await getDoc(userDoc);
    let returnData = dataPlayesRef.data();
    return returnData

};

export const acceptAplicationTeam = async (userToId: string, notification: any, user: any) => {

    //***** INVITATION TO TEAM PARTICIPATION    */
    if (notification.type == TypeNotifications.teamParticipation) {
        //update notification on user
        await updateNotificationToUser(notification.id, { show_alert: false, status: "acepted" }, notification.user_id)
        //add notfication to origin user
        addNotificationToUser(notification.origin_user_id, {
            created_at: new Date(),
            push_notification_sended: false,
            origin_user_id: userToId,
            status: "sended",
            text: ` ${i18n.t('theTeam')} ${notification.team_name} ${i18n.t('teamConfirmParticipationTournament')} ${notification.tournament_name}`,
            title: `${i18n.t('teamAcceptance')} ${notification.team_name}`,
            type: TypeNotifications.teamAcceptParticipation,
            show_alert: true,
            team_id: notification.team_id,
            tournament_id: notification.tournament_id,
            tournament_name: notification.tournament_name,
            team_name: notification.team_name,
            user_id: notification.origin_user_id
        })

    }

    //***** INVITATION TO PLAYER PARTICIPATION    */
    if (notification.type == TypeNotifications.playerInvitation) {
        //update notification on user
        await updateNotificationToUser(notification.id, { show_alert: false, status: "acepted" }, notification.user_id)
    }

    //***** INVITATION TO TECHNICAL PARTICIPATION    */
    if (notification.type == TypeNotifications.technicalStaff) {
        //update notification on user
        await updateNotificationToUser(notification.id, { show_alert: false, status: "acepted" }, notification.user_id)

        //add notfication to origin user
        addNotificationToUser(notification.origin_user_id, {
            created_at: new Date(),
            push_notification_sended: false,
            origin_user_id: userToId,
            status: "sended",
            text: `${user.firstname} ${user.lastname} ${i18n.t('acceptedAs')} ${notification.rol} ${i18n.t('ofTheTeam')} ${notification.team_name} ${i18n.t('forTheTournament')} ${notification.tournament_name}`,
            title: `${i18n.t('acceptanceOf')} ${notification.rol}`,
            type: TypeNotifications.technicalAcceptStaff,
            show_alert: true,
            team_id: notification.team_id,
            tournament_id: notification.tournament_id,
            tournament_name: notification.tournament_name,
            team_name: notification.team_name,
            user_id: notification.origin_user_id
        })

    }
    //***** INVITATION TO MANAGER  PARTICIPATION    */
    if (notification.type == TypeNotifications.managerInvitation) {
        const commentDoc = doc(db, `${TEAM_TABLE_NAME}/${notification.team_id}/${APPLICATION_TABLE_NAME}`, notification.id);
        await deleteDoc(commentDoc)
            .then(() => {
                console.log("Applications has been deleted");
            })
            .catch((err) => {
                console.log(err);
            });

        await updateNotificationToUser(notification.id, { show_alert: false, status: "acepted" }, notification.user_id)
        //add notfication to origin user
        addNotificationToUser(notification.origin_user_id, {
            created_at: new Date(),
            push_notification_sended: false,
            origin_user_id: userToId,
            status: "sended",
            text: `${user.firstname} ${user.lastname} ${i18n.t('acceptedAs')} ${notification.rol} ${i18n.t('ofTheTeam')} ${notification.team_name}`,
            title: `${i18n.t('acceptanceOf')} ${notification.rol}`,
            type: TypeNotifications.managerAcceptInvitation,
            show_alert: true,
            team_id: notification.team_id,
            user_id: notification.origin_user_id,
            team_name: notification.team_name,
        })

    }


}


export const addPlayerToTeam = async (localUser: any, player: any, team: any, tournament: any) => {
    //add notfication to origin user

    const playerDoc = doc(db, `${TOURNAMENT_TABLE_NAME}/${tournament.id}/${PLAYERS_TABLE_NAME}`, player.id);
    const dataPlayesRef = await getDoc(playerDoc);
    let returnPlayerData = dataPlayesRef.data();
    if (returnPlayerData) {
        alert(i18n.t('msgTeamAlreadyRegister'))
        return
    }
    addNotificationToUser(player.id, {
        created_at: new Date(),
        push_notification_sended: false,
        origin_user_id: localUser.id,
        status: "sended",
        text: `${localUser.firstname} ${localUser.lastname} ${i18n.t('toAddedAsPlayer')} ${team.name} ${i18n.t('participateTournament')} ${tournament.name}`,
        title: i18n.t('toAddedAsPlayer'),
        type: TypeNotifications.playerAcceptInvitation,
        show_alert: true,
        team_id: team.id,
        tournament_id: tournament.id,
        tournament_name: tournament.name,
        team_name: team.name,
        user_id: localUser.id
    })

    //add notfication to origin user
    addNotificationToUser(localUser.id, {
        created_at: new Date(),
        push_notification_sended: false,
        origin_user_id: localUser.id,
        status: "sended",
        text: `${player.firstname} ${player.lastname} ${i18n.t('toWasAddedAsPlayer')} ${team.name} ${i18n.t('participateTournament')} ${tournament.name}`,
        title: i18n.t('toAddedAsPlayer'),
        type: TypeNotifications.playerAcceptInvitation,
        show_alert: true,
        team_id: team.id,
        tournament_id: tournament.id,
        tournament_name: tournament.name,
        team_name: team.name,
        user_id: localUser.id
    })


    //add player to team on tournament
    await setDoc(doc(db, `${TEAM_TABLE_NAME}/${team.id}/${TOURNAMENT_TABLE_NAME}/${tournament.id}/${PLAYERS_TABLE_NAME}`, player.id),
        {
            id: player.id,
            created_at: new Date(),
            tournament_id: tournament.id,
            user_id: player.id,
            team_id: team.id,
            user_team_id: uuidv4().toString(),
            status: "acepted"
        }, { merge: true })

    const teamUid = uuidv4().toString();
    await setDoc(doc(db, `${USER_TABLE_NAME}/${player.id}/${TEAM_TABLE_NAME}`, teamUid),
        {
            created_at: new Date(),
            user_id: player.id,
            team_id: team.id,
            tournament_id: tournament.id,
            rol: "player"
        }, { merge: true })

    // add  user as player to tournament
    await setDoc(doc(db, `${USER_TABLE_NAME}/${player.id}/${TOURNAMENT_TABLE_NAME}`, teamUid),
        {
            created_at: new Date(),
            user_id: player.id,
            team_id: team.id,
            tournament_id: tournament.id,
            rol: "player"
        }, { merge: true })


    // add  user as player to tournament
    await setDoc(doc(db, `${TOURNAMENT_TABLE_NAME}/${tournament.id}/${PLAYERS_TABLE_NAME}`, player.id),
        {
            created_at: new Date(),
            user_id: player.id,
            team_id: team.id,
            tournament_id: tournament.id,
            rol: "player"
        }, { merge: true })


}



export const deniedAplicationTeam = async (userToId: string, notification: any, user: any) => {
    if (notification.type == TypeNotifications.teamParticipation) {
        //update notification on user
        await updateNotificationToUser(notification.id, { show_alert: false, status: "denied" }, notification.user_id)
        //update notification on application

        const tournamentApplication = doc(db, `${TOURNAMENT_TABLE_NAME}/${notification.tournament_id}/${APPLICATION_TABLE_NAME}`, notification.id);
        await deleteDoc(tournamentApplication)
            .then(() => {
                console.log("Applications tournamentApplication has been deleted");
            })
            .catch((err) => {
                console.log(err);
            });

        const tournamentTeam = doc(db, `${TOURNAMENT_TABLE_NAME}/${notification.tournament_id}/${TEAM_TABLE_NAME}`, notification.team_id);
        await deleteDoc(tournamentTeam)
            .then(() => {
                console.log("Applications tournamentTeam has been deleted");
            })
            .catch((err) => {
                console.log(err);
            });

        const teamTournament = doc(db, `${TEAM_TABLE_NAME}/${notification.team_id}/${TOURNAMENT_TABLE_NAME}`, notification.tournament_id);
        await deleteDoc(teamTournament)
            .then(() => {
                console.log("Applications teamTournament has been deleted");
            })
            .catch((err) => {
                console.log(err);
            });


        //add notfication to origin user
        addNotificationToUser(notification.origin_user_id, {
            created_at: new Date(),
            push_notification_sended: false,
            origin_user_id: userToId,
            status: "sended",
            text: `${i18n.t('theTeam')} ${notification.team_name} ${i18n.t('refusedParticipateTournament')} ${notification.tournament_name}`,
            title: `${i18n.t('teamAcceptance')} ${notification.team_name}`,
            type: TypeNotifications.teamDeniedParticipation,
            show_alert: true,
            team_id: notification.team_id,
            tournament_id: notification.tournament_id,
            tournament_name: notification.tournament_name,
            team_name: notification.team_name,
            user_id: notification.origin_user_id
        })


    }
    if (notification.type == TypeNotifications.playerInvitation) {
        //update notification on user
        await updateNotificationToUser(notification.id, { show_alert: false, status: "denied" }, notification.user_id)
        //update notification on application
        await setDoc(doc(db, `${TEAM_TABLE_NAME}/${notification.team_id}/${APPLICATION_TABLE_NAME}`, notification.id),
            {
                status: "denied"
            }, { merge: true })
        //add notfication to origin user

        const userTeamDoc = doc(db, `${USER_TABLE_NAME}/${notification.user_id}/${TEAM_TABLE_NAME}`, notification.team_id)
        await deleteDoc(userTeamDoc)
            .then(() => {
                console.log("Applications userTeamDoc has been deleted");
            })
            .catch((err) => {
                console.log(err);
            });


        const userTournamentPlayertDoc = doc(db, `${USER_TABLE_NAME}/${notification.user_id}/${TOURNAMENT_TABLE_NAME}`, notification.tournament_id)
        await deleteDoc(userTournamentPlayertDoc)
            .then(() => {
                console.log("Applications userTournamentPlayertDoc has been deleted");
            })
            .catch((err) => {
                console.log(err);
            });

        const tournamentPlayertDoc = doc(db, `${TOURNAMENT_TABLE_NAME}/${notification.tournament_id}/${PLAYERS_TABLE_NAME}`, notification.user_id)
        await deleteDoc(tournamentPlayertDoc)
            .then(() => {
                console.log("Applications tournamentPlayertDoc has been deleted");
            })
            .catch((err) => {
                console.log(err);
            });

        let teamTournamentPlayers = doc(db, `${TEAM_TABLE_NAME}/${notification.team_id}/${TOURNAMENT_TABLE_NAME}/${notification.tournament_id}/${PLAYERS_TABLE_NAME}`, notification.user_id);
        await deleteDoc(teamTournamentPlayers)
            .then(() => {
                console.log("Applications teamTournamentPlayers has been deleted");
            })
            .catch((err) => {
                console.log(err);
            });

        addNotificationToUser(notification.origin_user_id, {
            created_at: new Date(),
            push_notification_sended: false,
            origin_user_id: userToId,
            status: "sended",
            text: `${user.firstname} ${user.lastname} ${i18n.t('refusedPlayerTournament')} ${notification.team_name} ${i18n.t('participateTournament')} ${notification.tournament_name}`,
            title: `${i18n.t('playerAcceptance')}`,
            type: TypeNotifications.teamDeniedParticipation,
            show_alert: true,
            team_id: notification.team_id,
            tournament_id: notification.tournament_id,
            tournament_name: notification.tournament_name,
            team_name: notification.team_name,
            user_id: notification.origin_user_id
        })
    }

    if (notification.type == TypeNotifications.managerInvitation) {
        //update notification on user    
        await updateNotificationToUser(notification.id, { show_alert: false, status: "denied" }, notification.user_id)
        //delete team application  
        const teamApplicationDoc = doc(db, `${TEAM_TABLE_NAME}/${notification.team_id}/${APPLICATION_TABLE_NAME}`, notification.id);
        await deleteDoc(teamApplicationDoc)
            .then(() => {
                console.log("Applications teamApplicationDoc has been deleted");
            })
            .catch((err) => {
                console.log(err);
            });
        //delete team Manager  
        const teamManagerDoc = doc(db, `${TEAM_TABLE_NAME}/${notification.team_id}/${MANAGERS_TABLE_NAME}`, notification.manager_id);
        await deleteDoc(teamManagerDoc)
            .then(() => {
                console.log("Applications teamManagerDoc has been deleted");
            })
            .catch((err) => {
                console.log(err);
            });

        //add notfication to origin user
        addNotificationToUser(notification.origin_user_id, {
            created_at: new Date(),
            push_notification_sended: false,
            origin_user_id: userToId,
            status: "sended",
            text: `${user.firstname} ${user.lastname} ${i18n.t('rejectedAs')} ${notification.rol} ${i18n.t('ofTheTeam')} ${notification.team_name}`,
            title: `${i18n.t('acceptanceDirector')}`,
            type: TypeNotifications.manageDeniedtInvitation,
            show_alert: true,
            team_id: notification.team_id,
            team_name: notification.team_name,
            user_id: notification.origin_user_id
        })
    }
    if (notification.type == TypeNotifications.technicalStaff) {

        //update notification on user    
        await updateNotificationToUser(notification.id, { show_alert: false, status: "denied" }, notification.user_id)
        //update notification on application

        const teamApplication = doc(db, `${TEAM_TABLE_NAME}/${notification.team_id}/${APPLICATION_TABLE_NAME}`, notification.id);
        await deleteDoc(teamApplication)
            .then(() => {
                console.log("Applications teamApplication has been deleted");
            })
            .catch((err) => {
                console.log(err);
            });

        const userTeam = doc(db, `${USER_TABLE_NAME}/${notification.user_id}/${TEAM_TABLE_NAME}`, notification.user_team_id);
        await deleteDoc(userTeam)
            .then(() => {
                console.log("Applications  userTeam has been deleted");
            })
            .catch((err) => {
                console.log(err);
            });


        const userTournament = doc(db, `${USER_TABLE_NAME}/${notification.user_id}/${TOURNAMENT_TABLE_NAME}`, notification.user_team_id);
        await deleteDoc(userTournament)
            .then(() => {
                console.log("Applications userTournament has been deleted");
            })
            .catch((err) => {
                console.log(err);
            });

        const teamTournamentTechical = doc(db, `${TEAM_TABLE_NAME}/${notification.team_id}/${TOURNAMENT_TABLE_NAME}/${notification.tournament_id}/${TECHNICALSTAFF_TABLE_NAME}`, notification.user_id);
        await deleteDoc(teamTournamentTechical)
            .then(() => {
                console.log("Applications teamTournamentTechical has been deleted");
            })
            .catch((err) => {
                console.log(err);
            });



        //add notfication to origin user
        addNotificationToUser(notification.origin_user_id, {
            created_at: new Date(),
            push_notification_sended: false,
            origin_user_id: userToId,
            status: "sended",
            text: `${user.firstname} ${user.lastname} ${i18n.t('rejectedAs')} ${notification.rol} ${i18n.t('ofTheTeam')} ${notification.team_name}`,
            title: `${i18n.t('acceptanceOf')} ${notification.rol}`,
            type: TypeNotifications.technicalDeniedtStaff,
            show_alert: true,
            team_id: notification.team_id,
            team_name: notification.team_name,
            user_id: notification.origin_user_id
        })
    }
}

export const getTeamsFromTournamentByGroup = async (tournamentId: string, phaseId: string, groupId: string) => {
    const teamsCollectionRef = collection(db, `${TOURNAMENT_TABLE_NAME}/${tournamentId}/${PHASES_TABLE_NAME}/${phaseId}/${GROUP_TABLE_NAME}/${groupId}/${TEAM_TABLE_NAME}`);
    const teamsIds = await getDocs(teamsCollectionRef);
    // let teamsReturn = []
    // for (let team of teamsIds) {
    //     let teamData = await getTeamById(team.team_id)
    //     teamsReturn.push(teamData)
    // }
    return arrayElementLooper(teamsIds.docs);
}

export const getTeamsDetailsFromTournamentByGroup = async (tournamentId: string, phaseId: string, groupId: string) => {
    let teamsCollectionRef = collection(db, `${TOURNAMENT_TABLE_NAME}/${tournamentId}/${PHASES_TABLE_NAME}/${phaseId}/${TEAM_TABLE_NAME}`);
    if (groupId)
        teamsCollectionRef = collection(db, `${TOURNAMENT_TABLE_NAME}/${tournamentId}/${PHASES_TABLE_NAME}/${phaseId}/${GROUP_TABLE_NAME}/${groupId}/${TEAM_TABLE_NAME}`);
    const teamsIdsDoc = await getDocs(teamsCollectionRef);
    const teamsIds = arrayElementLooper(teamsIdsDoc.docs)
    let teamsReturn = []
    for (let team of teamsIds) {
        let teamData = await getTeamById(team.team_id)
        if (teamData.deleted === undefined || teamData.delete === false) {
            teamsReturn.push(teamData)
        }
    }
    return teamsReturn;
}

export const getTeamsFromTournamentByPhase = async (tournamentId: string, phaseId: string) => {
    const teamsCollectionRef = collection(db, `${TOURNAMENT_TABLE_NAME}/${tournamentId}/${PHASES_TABLE_NAME}/${phaseId}/${TEAM_TABLE_NAME}`);
    const teamsIds = await getDocs(teamsCollectionRef);
    // let teamsReturn = []
    // for (let team of teamsIds) {
    //     let teamData = await getTeamById(team.team_id)
    //     teamsReturn.push(teamData)
    // }
    return arrayElementLooper(teamsIds.docs);
}

export const getTeamsDetailsFromTournamentByPhase = async (tournamentId: string, phaseId: string) => {
    const teamsCollectionRef = collection(db, `${TOURNAMENT_TABLE_NAME}/${tournamentId}/${PHASES_TABLE_NAME}/${phaseId}/${TEAM_TABLE_NAME}`);
    const teamsIdsDoc = await getDocs(teamsCollectionRef);
    const teamsIds = arrayElementLooper(teamsIdsDoc.docs)
    let teamsReturn = []
    for (let team of teamsIds) {
        let teamData = await getTeamById(team.team_id)
        if (teamData.deleted === undefined || teamData.delete === false) {
            teamsReturn.push(teamData)
        }
    }
    return teamsReturn;
}

export const getTeamsAvailibleByPhase = async (tournamentId: string, phaseId: string) => {
    let allTeams = await getTeamsFromTournament(tournamentId)
    const phase: any = await getPhasseFromTournamentByPhaseId(tournamentId, phaseId)
    let teamsSelected: any = []
    if (phase.have_groups) {
        const groups = await getGroupFromTournamentByPhase(tournamentId, phaseId)
        for (let group of groups) {
            let teamsByGroup = await getTeamsFromTournamentByGroup(tournamentId, phaseId, group.id)
            teamsSelected = teamsSelected.concat(teamsByGroup)
        }
    } else {
        teamsSelected = await getTeamsFromTournamentByPhase(tournamentId, phaseId)
    }

    let teamsAvailible = []
    for (let teamFree of allTeams) {
        let exist = false
        for (let teamSelected of teamsSelected) {
            if (teamSelected.team_id == teamFree.id) {
                exist = true
            }
        }
        if (!exist) {
            teamsAvailible.push(teamFree)
        }
    }
    return teamsAvailible
}


export const assignTeamToGroupOrPhase = async (teams: any, tournament: any, phase: any, group: any) => {
    let returnValue = false
    if (phase.have_groups) {
        let currentTeams = await getTeamsFromTournamentByGroup(tournament.id, phase.id, group.id)
        for (let currentTeam of currentTeams) {
            const currentTeamsDoc = doc(db, `${TOURNAMENT_TABLE_NAME}/${tournament.id}/${PHASES_TABLE_NAME}/${phase.id}/${GROUP_TABLE_NAME}/${group.id}/${TEAM_TABLE_NAME}`, currentTeam.team_id);
            await deleteDoc(currentTeamsDoc)
        }
        for (let team of teams) {
            await setDoc(doc(db, `${TOURNAMENT_TABLE_NAME}/${tournament.id}/${PHASES_TABLE_NAME}/${phase.id}/${GROUP_TABLE_NAME}/${group.id}/${TEAM_TABLE_NAME}`, team.id),
                {
                    team_id: team.id
                }, { merge: true })
        }
    } else {
        let currentTeams = await getTeamsFromTournamentByPhase(tournament.id, phase.id)
        for (let currentTeam of currentTeams) {
            const currentTeamsDoc = doc(db, `${TOURNAMENT_TABLE_NAME}/${tournament.id}/${PHASES_TABLE_NAME}/${phase.id}/${TEAM_TABLE_NAME}`, currentTeam.team_id);
            await deleteDoc(currentTeamsDoc)
        }
        for (let team of teams) {
            await setDoc(doc(db, `${TOURNAMENT_TABLE_NAME}/${tournament.id}/${PHASES_TABLE_NAME}/${phase.id}/${TEAM_TABLE_NAME}`, team.id),
                {
                    team_id: team.id
                }, { merge: true })
        }
    }
    return returnValue
}


export const getTopTournamentsByUserId = async (userId: string) => {
    const tournamentsCollectionRef = collection(db, `${USER_TABLE_NAME}/${userId}/${TOURNAMENT_TABLE_NAME}`);
    const q = query(tournamentsCollectionRef, orderBy("created_at", "desc"), limit(20));
    const data = await getDocs(q);
    let tournamentsUser = arrayElementLooper(data.docs);
    let tournamentsReturn = []
    for (let tournament of tournamentsUser) {
        let tournamentData = await getTournamentById(tournament.tournament_id)
        tournamentsReturn.push(tournamentData)
    }
    return tournamentsReturn
}


export const getApplicationsByTournament = async (tournamentId: string) => {
    const tournamentsCollectionRef = collection(db, `${TOURNAMENT_TABLE_NAME}/${tournamentId}/${APPLICATION_TABLE_NAME}`);
    const q = query(tournamentsCollectionRef, where("status", "==", "sended"));
    const data = await getDocs(q);
    let applicationTeams = arrayElementLooper(data.docs);
    for (let application of applicationTeams) {
        let team = await getTeamById(application.team_id)
        application.team = team
    }
    return applicationTeams

}

// export const getRankingPlayersByTournament = async (tournamentId: string) => {
//     const tournamentsCollectionRef = collection(db, `${TOURNAMENT_TABLE_NAME}/${tournamentId}/${RANKING_TABLE_NAME}`);
//     const q = query(tournamentsCollectionRef, orderBy("goals", "desc"));
//     const data = await getDocs(q);
//     let applicationTeams = arrayElementLooper(data.docs);
//     return applicationTeams;

// }

export const getRankingPlayerByTournamentAndUserId = async (tournamentId: string, playerId: string) => {
    const phaseDoc = doc(db, `${TOURNAMENT_TABLE_NAME}/${tournamentId}/${RANKING_TABLE_NAME}`, playerId);
    let returnData = null;
    await getDoc(phaseDoc)
        .then((snapshot: any) => {
            if (snapshot.exists()) {
                returnData = snapshot.data();
                returnData.id = snapshot.id;
            } else {
                return null;
            }
        })
        .catch((err) => {
            console.log(err);
            return null;
        });
    return returnData;

}



export const saveRankingUsersToTournament = async (incidenceData: any) => {
    let userDataRanking: any = await getRankingPlayerByTournamentAndUserId(incidenceData.tournament_id, incidenceData.player_id)
    let existe = false;
    if (!userDataRanking) {
        userDataRanking = {
            user_id: incidenceData.player_id,
            team_id: incidenceData.team_id,
            matchs: 1,
            goals: incidenceData.type == IncidenceTypes.goal ? 1 : 0,
            yellow_cards: incidenceData.type == IncidenceTypes.yellow_card ? 1 : 0,
            red_cards: incidenceData.type == IncidenceTypes.red_card ? 1 : 0
        }
        existe = true;
    }
    if (!existe) {
        if (incidenceData.type == IncidenceTypes.goal) {
            userDataRanking.goals = userDataRanking.goals + 1
        }
        if (incidenceData.type == IncidenceTypes.yellow_card) {
            userDataRanking.yellow_cards = userDataRanking.yellow_cards + 1
        }
        if (incidenceData.type == IncidenceTypes.red_card) {
            userDataRanking.red_cards = userDataRanking.red_cards + 1
        }
    }
    await setDoc(doc(db, `${TOURNAMENT_TABLE_NAME}/${incidenceData.tournament_id}/${RANKING_TABLE_NAME}`, incidenceData.player_id),
        userDataRanking, { merge: true })
    return userDataRanking

}



export const deleteRankingUsersToTournament = async (incidenceData: any) => {
    let userDataRanking = await getRankingPlayerByTournamentAndUserId(incidenceData.tournament_id, incidenceData.player_id)
    if (userDataRanking) {
        if (incidenceData.type == IncidenceTypes.goal) {
            userDataRanking.goals = userDataRanking.goals - 1
        }
        if (incidenceData.type == IncidenceTypes.yellow_card) {
            userDataRanking.yellow_cards = userDataRanking.yellow_cards - 1
        }
        if (incidenceData.type == IncidenceTypes.red_card) {
            userDataRanking.red_cards = userDataRanking.red_cards - 1
        }
        await setDoc(doc(db, `${TOURNAMENT_TABLE_NAME}/${incidenceData.tournament_id}/${RANKING_TABLE_NAME}`, incidenceData.player_id),
            userDataRanking, { merge: true })
        return userDataRanking
    }
}

